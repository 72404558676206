import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { injectIntl } from 'react-intl';

import { Spinner, Row, Col, Button } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FormattedMessage } from 'react-intl'
import { FormattedDate, FormattedTime } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faClipboard, faStar } from '@fortawesome/free-solid-svg-icons';

import PlaylistClipModal from 'Components/Modal/PlaylistClipModal';
import RegisterModal from 'Components/Modal/RegisterModal';

import * as Output from 'Util/Output';

import './Detail.scss';

import store from 'index';

const Detail = observer(
    class Detail extends Component {
        state = {
            load: true,
            next: -1,
            prev: -1,
            activeKey: 'home',
        }

        searchStore = store.searchStore;
        paginationStore = store.paginationStore;

        constructor(props) {
            super(props);
            this.state = { ...this.state, ...Detail.init(props) };
        }

        static getDerivedStateFromProps(props, state) {
            if (props.location.state && parseInt(props.location.state.id) !== parseInt(state.id)) {
                return {
                    ...Detail.init(props),
                    load: true
                };
            }
            return null;
        }

        static init(props) {
            const state = {};

            if (props.location.state && props.location.state.id && props.location.state.ids) {
                state.id = props.location.state.id;

                const index = props.location.state.ids.indexOf(props.location.state.id);
                if (index !== -1) {
                    state.index = index;
                    state.results = props.location.state.ids.length;
                    state.next = Detail.getNext(index, props.location.state.ids);
                    state.prev = Detail.getPrev(index, props.location.state.ids);
                }
            }

            return state;
        }

        static getNext(id) {
            const ids = store.searchStore.getOrder();
            const index = ids.indexOf(id);
            // check index + 1 because length starts with 1 and index with 0
            if (ids.length > index + 1) {
                return ids[index + 1];
            }
            return -1;
        }

        static getPrev(id) {
            // check index + 1 because length starts with 1 and index with 0
            const ids = store.searchStore.getOrder();
            const index = ids.indexOf(id);
            if (index - 1 >= 0) {
                return ids[index - 1];
            }
            return -1;
        }

        locationHandler = (id) => {
            if (id !== -1) {
                this.props.history.push({
                    pathname: '/detail/' + id,
                    search: this.searchStore.createUrl(),
                    state: {
                        id: id,
                        ids: (this.props.location.state && this.props.location.state.ids) || []
                    }
                });
            }
        }

        //ACCESSIBILITY BEGIN
        checkAndUpdateAccessNav = () => {
            const listItemElements = document.querySelectorAll("li.nav-item a");
            for (let listItemEl of listItemElements) {
                listItemEl.setAttribute("tabindex", "0");
            }
        }

        checkAndUpdateAccessTab = () => {
            const listItemElements = document.querySelectorAll(".nav-tabs li");
            for (let listItemEl of listItemElements) {
                listItemEl.classList.add("detailtab");
                listItemEl.setAttribute("tabindex", "0");
                listItemEl.setAttribute("role", "none");
                let btnTab = listItemEl.firstChild;
                btnTab.setAttribute("role", "button");
                btnTab.removeAttribute("aria-selected")
                listItemEl.addEventListener("keyup", (e) => {
                    if (e.key == 'Enter') {
                        let liEvent;
                        if (btnTab) {
                            liEvent = btnTab.getAttribute("data-rr-ui-event-key");
                        }
                        if (liEvent) this.setState({ activeKey: liEvent });
                    }
                });
            }
        }

        changeAccessTitle = ({ clip }) => {
            const titleEl = document.querySelector("title");
            let headerTitle = this.props.intl.formatMessage({ id: "header.title" });
            let clipTitle = "";
            if (clip && clip.metadata && clip.metadata.fields &&
                (clip.metadata.fields.title || clip.metadata.fields.seriestitle)) {
                clipTitle = clip.metadata.fields.title || clip.metadata.fields.seriestitle;
                if (headerTitle && clipTitle) {
                    titleEl.textContent = `${headerTitle}: ${clipTitle}`;
                }
            } else {
                titleEl.textContent = headerTitle;
            }
        }

        componentWillUnmount() {
            this.changeAccessTitle({});
        }
        //ACCESSIBILITY END

        componentDidMount() {
            this.search();
            window.scrollTo(0, 0);

            //ACCESSIBILITY BEGIN
            this.checkAndUpdateAccessNav();
            //ACCESSBILITY END
        }

        componentDidUpdate() {
            if (this.state.load) {
                this.search();
                this.setState({ load: false });
            }
        }

        /*
        search = () => {
            if (this.state.load) {
                const id = this.props.match.params.id;
                window.flow.getClipById(id).then(res => {
                    if (res.data) {
                        this.setState({ clip: res.data, load: false });
                        //ACCESSIBILITY BEGIN
                        this.changeAccessTitle({ clip: res.data });
                        this.checkAndUpdateAccessTab();
                        //ACCESSIBILITY END
                    }
                });
            }
        }
        */
        search = () => {
            if (this.state.load) {
                const id = this.props.match.params.id;
                const search = [];
                search.push({field: 'id', type: '=', value: id});
                search.push({field: "", type: "BEGIN_OR", value: ""});
				search.push({field:"status",type:"=",value:"published_internal"});
				search.push({field:"status",type:"=",value:"published_public"});
				search.push({field: "", type: "END_OR", value: ""});
                
                
                window.flow.getClipsByFields(search, { limit: 1 }).then(res => {
                    if(res.count !== "0" && res.clip && res.clip.order.length) {
                        this.setState({ clip: res.clip.elements[res.clip.order[0]], load: false });
                        
                        //ACCESSIBILITY BEGIN
                        this.changeAccessTitle({ clip: res.clip.elements[res.clip.order[0]] });
                        this.checkAndUpdateAccessTab();
                        //ACCESSIBILITY END
                    } else {
                        this.setState({ clip: false, load: false });
                    }
                }).catch(res => {
                    this.setState({ clip: false, load: false });
                });
            }
        }

        back = () => {
            this.props.history.push({
                pathname: '/search',
                search: this.searchStore.createUrl()
            });
        }

        onKeyUpAddPlaylist = (cb) => (e) => {
            if (e.key === 'Enter') cb();
        }

        addToPlaylist = () => {
            /*if(!this.props.user) {
                return "";
            }*/
            let callback = (evt) => {
                this.setShowModal(true, evt, this.state.clip.id);
            }
            if (!this.props.user) {
                callback = (evt) => {
                    this.setShowRegisterModal(true, evt);
                }
            }

            let icon = faStarRegular;
            let cls = "";

            if (this.state.clip && store.accountStore.isClipUsed(this.state.clip.id)) {
                icon = faStar;

            }

            return (
                <span className="AddToPlaylistIcon" tabIndex="0"
                    onKeyUp={this.onKeyUpAddPlaylist((evt) => callback(evt))}>
                    <FontAwesomeIcon
                        className={"AddToPlaylist " + cls}
                        icon={icon}
                        onClick={(evt) => callback(evt)/*this.setShowModal(true, evt, this.state.clip.id)*/}
                        title={this.props.intl.formatMessage({ id: "button.playlist.clip.add" })} />
                </span>);
        }

        setShowModal = (show, evt, id = -1) => {
            this.setState({ showModal: show, playlistClipId: id });
        }

        setShowRegisterModal = (show, evt) => {
            this.setState({ showRegisterModal: show });
        }

        onKeyUpClipBoard = (value) => (e) => {
            if (e.key === 'Enter') this.onCopyToClipboard(value);
        }

        onCopyToClipboard = (value) => {
            navigator.clipboard.writeText(value).then(function () {
                console.log('Async: Copying to clipboard was successful!');
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        }

        renderCopyToClipboard = (value) => {
            return (
                <FontAwesomeIcon
                    className="CopyToClipBoard" tabIndex="0" onKeyUp={this.onKeyUpClipBoard(value)}
                    onClick={() => this.onCopyToClipboard(value)}
                    icon={faClipboard} color="" title={this.props.intl.formatMessage({ id: "common.copyToClipBoard" })} />
            );
        }

        renderLine = (meta, label, field) => {
            const fastCopy = ["title", "seriestitle", "episodetitle", "summary", "shotlist_internal", "keywords", "itvid", "nlwid", "performers", "othercontributors", "producer", "editor", "director", "creator"];
            if (Output.isValid(field, meta)) {
                let copy = "";
                if (fastCopy.includes(field)) {
                    copy = this.renderCopyToClipboard(Output.getOutput(field, meta));
                }
                return (
                    <div className={"OutputBox"}>
                        <h3 className={"Label"}>
                            <span><FormattedMessage id={"metadata.clip." + field} /></span>
                            {copy}
                        </h3>
                        <p className={"Text"}>{Output.getOutput(field, meta)}</p>
                    </div>
                );
            }
            return "";
        }

        render() {
            if (this.state.load) {
                return (
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                );
            }
            
            if (!this.state.clip) {
                return (
                    <Col className="text-center">
                        <p><FormattedMessage id={"search.toolbar.viewing.zero"} /> id {this.props.match.params.id}</p>
                    </Col>
                );
            }

            const meta = this.state.clip.metadata;
            const ids = store.searchStore.getOrder();
            const index = ids.indexOf(this.state.clip.id);
            const MediaOutput = Output.getMediaOutput();

            return (
                <>
                    <Col className="DetailPage">
                        <Row className={"PageHeader"}>
                            <Col className="col-auto"><h1>{meta.fields.title || meta.fields.seriestitle}</h1></Col>
                            <Col className="col-auto">{this.renderCopyToClipboard(meta.fields.title || meta.fields.seriestitle)}</Col>
                            <Col></Col>
                            <Col className="col-auto">{process.env.REACT_APP_SERVER !== "EXT" && this.addToPlaylist()}</Col>
                            <Col className="col-auto">
                                <Button variant="primary" onClick={this.back}>
                                    <FormattedMessage id="page.detail.button.back" />
                                </Button>
                            </Col>
                        </Row>

                        <Row className={"Media"}>
                            <Col md={1} className={"Control d-none d-md-flex"}>
                                <span
                                    className={"Prev"}
                                    title={this.props.intl.formatMessage({ id: "page.detail.control.prev", defaultMessage: "Previous" })}
                                    onClick={() => this.locationHandler(Detail.getPrev(this.state.clip.id)/*this.state.prev*/)}></span>
                            </Col>
                            <Col>
                                {
                                    <MediaOutput 
                                        key={this.state.clip.id} 
                                        data={this.state.clip} 
                                        user={this.props.user} />
                                }
                            </Col >
                            <Col md={1} className={"Control d-none d-md-flex"}>
                                <span
                                    className={"Next"}
                                    title={this.props.intl.formatMessage({ id: "page.detail.control.next", defaultMessage: "Next" })}
                                    onClick={() => this.locationHandler(Detail.getNext(this.state.clip.id)/*this.state.next*/)}></span>
                            </Col>
                        </Row >
                        <Row className={"d-none d-md-flex"}>
                            <Col>
                                <p className={"Results"}>
                                    {store.searchStore.searching ? "Loading ..." : <FormattedMessage id="page.detail.info.show" values={{ results: this.searchStore.getLimit()/*this.state.results*/, result: index + 1 }} />}
                                </p>
                                <p className={"ResultsPage"}>
                                    (<FormattedMessage id="page.detail.info.page" values={{ page: this.paginationStore.page + 1 }} />)
                                </p>
                            </Col>
                        </Row>
                        <Row className={"Content"}>
                            <Col md={1} className={"d-none d-md-block"}></Col>
                            <Col>
                                <Tabs
                                    activeKey={this.state.activeKey}
                                    aria-busy="true"
                                    defaultActiveKey="home"
                                    onSelect={(k) => this.setState({ activeKey: k })}
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3">
                                    <Tab eventKey="home" title={this.props.intl.formatMessage({ id: "page.detail.tab.overview", defaultMessage: "Overview" })}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                {this.renderLine(meta, "Series title", "seriestitle")}
                                                {this.renderLine(meta, "Episode", "episodetitle")}
                                                {this.renderLine(meta, "Alternative title", "alternativetitle")}
                                                {this.renderLine(meta, "Genre", "genre")}
                                                {this.renderLine(meta, "Channel name", "channelname")}
                                            </Col>
                                            <Col sm={12} md={6}>
                                                {this.renderLine(meta, "Broadcast at", "broadcast_at")}
                                                {this.renderLine(meta, "Production date", "production_date")}
                                                {this.renderLine(meta, "Language", "language")}
                                                {this.renderLine(meta, "Content type", "contenttype")}
                                                {this.renderLine(meta, "Content alert", "contentalert")}
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col>
                                                {this.renderLine(meta, "Summary", "summary")}
                                                {this.renderLine(meta, "Shot list", "shotlist_internal")}
                                                {this.renderLine(meta, "Keywords", "keywords")}
                                                {this.renderLine(meta, "Location", "location")}
                                                {this.renderLine(meta, "Subjects", "subjects")}
                                                {this.renderLine(meta, "Rights", "rights")}
                                                {this.renderLine(meta, "Note(s)", "notes")}
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="extended" title={this.props.intl.formatMessage({ id: "page.detail.tab.extended", defaultMessage: "Extended Data" })}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                {this.renderLine(meta, "Still image", "stillimage")}
                                                {this.renderLine(meta, "Duration", "duration")}
                                                {this.renderLine(meta, "Sound", "sound")}
                                                {this.renderLine(meta, "Colour", "colour")}
                                                {this.renderLine(meta, "Aspect ratio", "aspectratio")}
                                                {this.renderLine(meta, "External links", "externallinks")}
                                                {this.renderLine(meta, "NLW filename", "identifier")}
                                                {this.renderLine(meta, "Original filename", "originalfilename")}
                                                {this.renderLine(meta, "NLW ID", "nlwid")}
                                                {this.renderLine(meta, "Source", "itvid")}
                                            </Col>
                                            <Col sm={12} md={6}>
                                                {this.renderLine(meta, "Creator", "creator")}
                                                {this.renderLine(meta, "Production company", "productioncompany")}
                                                {this.renderLine(meta, "Producer", "producer")}
                                                {this.renderLine(meta, "Director", "director")}
                                                {this.renderLine(meta, "Editor", "editor")}
                                                {this.renderLine(meta, "Other contributors", "othercontributors")}
                                                {this.renderLine(meta, "Performers", "performers")}
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col md={1} className={"d-none d-md-block"}></Col>
                        </Row>
                    </Col >

                    <PlaylistClipModal
                        show={this.state.showModal}
                        onHide={(evt) => this.setShowModal(false, evt)}
                        playlists={this.props.playlists}
                        id={this.state.playlistClipId}
                        showPlaylistModal={this.props.showPlaylistModal} />
                    <RegisterModal
                        show={this.state.showRegisterModal}
                        onHide={(evt) => this.setShowRegisterModal(false, evt)} />

                </>
            );
        }
    }
)

export default Flow.injectFlow(withRouter(injectIntl(Detail)));

import  { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import CheckboxFilter from './CheckboxFilter';

import './Filter.scss';

const Language = observer(
    class Language extends Component {
        
        data = [
            { value:'English', label: this.props.intl.formatMessage({id: "filter.language.english"}) },
            { value:'Welsh', label: this.props.intl.formatMessage({id: "filter.language.welsh"}) },
            { value:'No linguistic content', label: this.props.intl.formatMessage({id: "filter.language.no"}) },
            { value:'English; Welsh', label: this.props.intl.formatMessage({id: "filter.language.english.welsh"}) },
            { value:'Welsh; English', label: this.props.intl.formatMessage({id: "filter.language.welsh.english"}) },
            { value:'Other', label: this.props.intl.formatMessage({id: "filter.language.other"}) }
        ];
      
        state = {
            field: 'language'
        };
        
        constructor(props) {
            super(props);
        }
        
        createSearch = (filter) => {
            const search = [];
            
            if(filter.length) {
                search.push({field: "", type: "BEGIN_OR", value: ""});
                
                filter.forEach(value => {
                    search.push({field: this.state.field, type:"CONTAINS", value: value});
                });
                
                search.push({field: "", type: "END_OR", value: ""});
            }
            
            return search;
        }

        render() {
            return <CheckboxFilter 
                {...this.props } 
                data={this.data} 
                field={this.state.field}
                title={this.props.intl.formatMessage({id: "filter.language"})}
                createSearch={this.createSearch}
                facetCount={(this.props.facetCount && this.props.facetCount[this.state.field]) || false} />;
        }
    }
)

export default injectIntl(Language);

import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import { Spinner, Row, Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { login } from 'Util/Login';

import './Login.scss';

const Login = observer(
    class Login extends Component {
        state = {
            visibility: "password",
            user: "",
            pwd: "",
            error: false
        }

        onSubmit = (evt) => {
            evt.preventDefault();
            this.login();
            return false;
        }
		
		componentDidMount() {
			window.scrollTo(0,0);
        }
		
		onLoginError = (state) => {
			this.setState({ error: state });
		}
        
        login = () => {
            login({ ...this.props, user: this.state.user, pwd: this.state.pwd, onLoginError: this.onLoginError });
        }

        renderEye = () => {
            if (this.state.visibility === "password") {
                return (
                    <FontAwesomeIcon icon={faEye} onClick={
                        () => this.setState({ visibility: "text" })
                    } />
                );
            }

            return (
                <FontAwesomeIcon icon={faEyeSlash} onClick={
                    () => this.setState({ visibility: "password" })
                } />
            );
        }

        renderError = () => {
            if (this.state.error) {
                return (
                    <div className={"LoginError"}>
                        <FormattedMessage id="page.login.error" />
                    </div>
                );
            }
        }

        render() {
            return (
                <Col className={"LoginPage"}>
                    <Row>
                        <Col md={1} className={"d-none d-lg-block"}></Col>
                        <Col>
                            <Row className={"PageHeader"}>
                                <Col><h1><FormattedMessage id="page.login.title" /></h1></Col>
                            </Row>
                            <Row>
                                <Col className={"LoginImage d-none d-md-block"} md={6} >
                                    <img src="/login-page-image.png" alt="" />
                                </Col >
                                <Col className={"LoginForm"} md={6}>
                                    <Form className={""} onSubmit={this.onSubmit}>
                                        <Form.Group>
                                            <Col>
                                                <h4>
                                                    <FormattedMessage id="page.login.label.ticket" />
                                                </h4>
                                                <div className={"LoginNumber"}>
                                                    <Form.Control
                                                        aria-label="login-number"
                                                        type="text"
                                                        onChange={(evt) => this.setState({ user: evt.target.value })} />
                                                </div>
                                                <h4>
                                                    <FormattedMessage id="page.login.label.password" />
                                                </h4>
                                                <div className={"LoginPassword"}>
                                                    <Form.Control
                                                        aria-label="login-pw"
                                                        type={this.state.visibility}
                                                        onChange={(evt) => this.setState({ pwd: evt.target.value })} />
                                                    {this.renderEye()}
                                                </div>
                                                {this.renderError()}
                                                <div className={"LoginForgot"}>
                                                    <p>
                                                        <a target="_blank" href="https://ailosod.llgc.org.uk/?lang=en">
                                                            <FormattedMessage id="page.login.link.forgot" />
                                                        </a>?
                                                    </p>
                                                </div>
                                                <Button variant="primary" type="submit">
                                                    <FormattedMessage id="common.signin" />
                                                </Button>
                                                <div className={"LoginRegister"}>
                                                    <p><FormattedMessage id="page.login.text.register" /> <a target="_blank" href="https://psr.llgc.org.uk/psr/psr/register/en/personal"><FormattedMessage id="page.login.link.register" /></a>.</p>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1} className={"d-none d-lg-block"}></Col>
                    </Row >
                </Col>
            );
        }
    }
)

export default Flow.injectFlow(withRouter(Login));

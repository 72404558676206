import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'

import * as Output from 'Util/Output';

import './Clip.scss';

import store from 'index';

class ClipHCard extends Component {
    state = {
        data: false
    }

    searchStore = store.searchStore;

    constructor(props) {
        super(props);
        this.state.data = props.data;
    }

    onDetail = (id) => {
        //this.props.history.push({ pathname: '/detail/' + id, state: { id: id, ids: this.props.ids } });
        this.props.history.push({ pathname: '/detail/' + id, search: this.searchStore.createUrl(), state: { id: id, ids: [...this.props.ids] } });
    }

    getThumb = (clip) => {
        if (clip.mainthumbnail && clip.mainthumbnail.getUrl()) {
            let url = clip.mainthumbnail.getUrl() + '&width=750&height=420';

            if (/*process.env.REACT_APP_SERVER === "PROD" || */
                process.env.REACT_APP_SERVER === "EXT"/* ||
                 process.env.REACT_APP_SERVER === "TEST"*/) {
                try {
                    if (url.indexOf('http') === -1) {
                        url = window.location.protocol + url;
                    }
                    url = new URL(url);
                    url = window.location.origin + url.pathname + url.search;
                } catch (ex) {

                }
            }
            return <img alt={clip.title || ""} src={url} />;
        }

        return (
            <div className={"Icon"}>
                <FontAwesomeIcon icon={faFile} size={this.props.size || "10x"} />
            </div>
        );
    }
    /*
    getThumb = (clip) => {
        if(clip.mainthumbnail && clip.mainthumbnail.getUrl()) {
            return <img alt={""} src={this.state.data.mainthumbnail.getUrl() + '&width=750&height=420'} />;
        }
        
        return (
            <div className={"Icon"}>
                <FontAwesomeIcon icon={faFile} size={this.props.size || "10x"} />
            </div>
        );
    }*/

    getCardText = (meta) => {
        const output = [];
        const lines = [];

        lines[lines.length] = ["title", "seriestitle"].filter(item => {
            return meta.fields[item];
        });
        lines[lines.length] = ["episodetitle", "alternativetitle"].filter(item => {
            return meta.fields[item];
        });
        lines[lines.length] = ["genre", "production_date", "broadcast_at"].filter(item => {
            return Output.isValid(item, meta);
        });
        lines[lines.length] = ["subjects", "contentalert"].filter(item => {
            return meta.fields[item];
        });
        lines[lines.length] = ["channelname", "duration", "sound", "colour"].filter(item => {
            return Output.isValid(item, meta);
        });
        lines[lines.length] = ["summary"].filter(item => {
            return meta.fields[item];
        });

        lines.forEach((line, index) => {
            if (line.length) {
                let data = line.map(item => {
                    return Output.getOutput(item, meta);
                });
                if (!index) {
                    //output.push(<h3 key={Math.random()}>{data.join(' | ')}</h3>);
                    //ACCESSIBILITY
                    output.push(<h2 key={Math.random()} style={{ color: '#484848' }}>{data.join(' | ')}</h2>);
                } else {
                    output.push(<p key={Math.random()}>{data.join(' | ')}</p>);
                }
            }
        });

        return output;
    }

    render() {
        if (!this.state.data)
            return "";

        const clip = this.state.data;
        const meta = clip.getMetadata();
        const thumb = this.getThumb(clip);
        const text = this.getCardText(meta);

        return (
            <Card className={"ClipList"} onClick={() => this.onDetail(clip.getId())}>
                <Row>
                    <Col md={4}>{thumb}</Col>
                    <Col>{text}</Col>
                </Row>
                <div className={"CardControls"}>
                    {process.env.REACT_APP_SERVER !== "EXT" && this.props.controls}
                </div>
            </Card>
        );
    }
}

export default withRouter(ClipHCard);

import { Navbar, Nav, Container, Row, Col, Spinner, /*Form,*/  Button, NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Link, withRouter } from 'react-router-dom';
import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPlus, faMinus, faTrash, faPen, faShareAlt } from '@fortawesome/free-solid-svg-icons'

import * as PlaylistUtil from 'Util/Playlist';

import PlaylistModal from 'Components/Modal/PlaylistModal';

import './PlaylistPanel.scss';

import store from 'index';

const PlaylistPanel = observer(
	class PlaylistPanel extends Component {

		state = {
			show: true
		}

		constructor(props) {
			super(props);
		}

		static getDerivedStateFromProps(props, state) {
			return null;
		}

		toggle = () => {
			this.setState({ show: !this.state.show });
		}

		setShowModal = (show) => {
			this.setState({ showModal: show });
		}

		onAdd = () => {
			this.setState({ edit: null, share: null });
			this.setShowModal(true);
		}

		onShare = (evt, index) => {
			evt.preventDefault();
			evt.stopPropagation();
			this.setState({ edit: null, share: this.state.playlists[index] });
			this.setShowModal(true);
		}

		onDelete = (evt, index) => {
			evt.preventDefault();
			evt.stopPropagation();
			const playlist = store.accountStore.getPlaylist(index);
			if (playlist) {
				store.accountStore.removePlaylist(playlist.getId(), index);
			}
		}

		onEdit = (evt, index) => {
			evt.preventDefault();
			evt.stopPropagation();
			this.setState({ share: null, edit: store.accountStore.getPlaylist(index) });
			this.setShowModal(true);
		}

		renderLoading = () => {
			return (
				<Spinner animation="border" variant="secondary" />
			);
		}

		renderControls = (list, index) => {
			return (
				<Col className={"Controls"} md="auto">
					<Button variant="outline-dark" title={this.props.intl.formatMessage({ id: "button.playlist.edit" })} onClick={(evt) => this.onEdit(evt, index)}>
						<FontAwesomeIcon icon={faPen} />
					</Button>
					<Button variant="outline-dark" title={this.props.intl.formatMessage({ id: "button.playlist.delete" })} onClick={(evt) => this.onDelete(evt, index)}>
						<FontAwesomeIcon icon={faTrash} />
					</Button>
				</Col>
			);
		}

		renderPlaylists = () => {
			const playlists = store.accountStore.getPlaylists();

			if (!playlists.length) return "";

			const active = store.accountStore.getIndex();

			const output = playlists.map((list, index) => {
				let count = list.loading ? this.renderLoading() : list.getCount() || 0;
				const cls = active === index ? "active" : "";

				return (
					<div key={list.getId()} className={"PlaylistEntryBox"}>
						<div key={list.getId()} className={"PlaylistEntry " + cls} onClick={() => store.accountStore.setCurrent(index)}>

							<Row>
								<Col><h4>{list.getName()}</h4></Col>
								{this.renderControls(list, index)}
							</Row>
							<Row>
								<Col>{list.getDescription()}</Col>
							</Row>
							<Row>
								<Col><FormattedMessage id="common.entries" />: {count}</Col>
								<Col md="auto">
									{list.date}
								</Col>
							</Row>

						</div>
					</div>
				);
			});
			return output;
		}

		render() {
			return (
				<>
					<Col className={"Sidebar"} >
						<Row className={"PageHeader"}>
							<Col>
								<Button variant="dark" onClick={this.onAdd}>
									<FormattedMessage id="page.playlist.add" />
								</Button>
							</Col>
						</Row>

						<Row className={""/*"Mobile"*/} >
							<Col>
								<div className={"SidebarControl"} onClick={this.toggle}>
									<h3><FormattedMessage id="page.playlist.playlists" /></h3>
									{this.state.show ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
								</div>
							</Col>
						</Row>
						<div className={["Filters", "show-" + this.state.show].join(' ')}>
							{this.renderPlaylists()}
						</div>
					</Col>
					<PlaylistModal
						{...this.props}
						show={this.state.showModal}
						onHide={() => this.setShowModal(false)}
						edit={this.state.edit}
						share={this.state.share}
					/>
				</>
			);
		}
	}
)

export default Flow.injectFlow(injectIntl(withRouter(PlaylistPanel)));

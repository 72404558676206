import { makeAutoObservable, action, runInAction, reaction } from "mobx"

export default class PaginationStore {
    
    rootStore = null;
    maxPages = 3;
    
    page = 0;
    
    count = 0;
    last = 0;
    pageCount = 0;
    pages = 0;
    //results = 12;
    
    constructor(rootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            maxPages: false
        });
        this.rootStore = rootStore;
    }
    
    setPage = (page) => {
        this.page = page;
        this.rootStore.searchStore.search();
    }
    
    changePage = (page) => {
        this.page = page;
    }
    
    setCount = (newCount) => {
        const count = parseInt(newCount);
            const results = this.rootStore.uiStore.getResults();
            const correct = count % results/*props.results*/ ? 0 : -1;
            const last = count ? Math.floor(count / results/*props.results*/) + correct : 0;
            
            this.count = count;
            this.last = last;
            this.pageCount = last + 1;
            this.pages = last < this.maxPages ? last + 1 : this.maxPages;
    }
    
    
    setCountOld = (newCount) => {
        if(parseInt(newCount) !== this.count) {
            //parseInt(count);
            
            const count = parseInt(newCount);
            const results = this.rootStore.uiStore.getResults();
            const correct = count % results/*props.results*/ ? 0 : -1;
            const last = count ? Math.floor(count / results/*props.results*/) + correct : 0;
            
            this.count = count;
            this.last = last;
            this.pageCount = last + 1;
            this.pages = last < this.maxPages ? last + 1 : this.maxPages;
            //this.results = 12/*props.results*/;
        }
    }
    
    init = () => {
        const params = new URL(document.location).searchParams.getAll("page");
        if(params.length) {
            this.page = parseInt(params[0]);
        }
    }
    
}


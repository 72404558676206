import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { Row, Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import store from 'index';

import './Search.scss';

const Search = observer(
    class Search extends Component {

        state = {
            fulltext: false
        }

        search = () => {
            store.paginationStore.changePage(0);
            //this.props.setSearch(true, this.state.fulltext, this.props.field);
            //this.props.history.push({ pathname: '/search' });
            if (this.props.onSearch) {
                this.props.onSearch();
            } else {
                this.props.history.push({ pathname: '/search' });
                store.searchStore.search();
            }
        }

        onSubmit = (evt) => {
            evt.preventDefault();

            this.search();
            return false;
        }

        getValue = () => {
            if (typeof this.state.fulltext === "string") {
                return this.state.fulltext;
            }
            return this.props.fulltext || "";
        }

        render() {
            let placeholder = "";
            if (this.props.placeholder) {
                placeholder = this.props.intl.formatMessage({ id: this.props.placeholder, defaultMessage: "Search" });
            }

            let search = this.props.intl.formatMessage({ id: "common.search", defaultMessage: "Search" }).toUpperCase();
            if (this.props.icon) {
                search = (<FontAwesomeIcon icon={faSearch} />);
            }
            return (
                <Form className={"Search"} onSubmit={this.onSubmit}>
                    <Form.Group>
                        <Row>
                            <Col>
                                <div className={"SearchInput"}>
                                    <Form.Control
                                        aria-label="search"
                                        value={this.props.getValue() || ""}
                                        type="text"
                                        placeholder={placeholder}
                                        onChange={(evt) => this.props.setValue(evt.target.value)/*this.setState({fulltext:evt.target.value})*/} />
                                    <Button variant="primary" onClick={this.search} aria-label="search-btn">
                                        {search}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            );
        }
    }
)

export default withRouter(Flow.injectFlow(injectIntl(Search)));

import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import { Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import Clip from 'Components/ClipGrid';

import { _LANG_EN_, _LANG_CY_ } from "Config/constants";

import './Lanes.scss';

import store from 'index';

const Lanes = observer(
	class Lanes extends Component {


		//ACCESSBILITY BEGIN

		updateCarousel = (elements, arialb) => {
			if (elements && elements.length) {
				for (let el of elements) {
					el.setAttribute("aria-label", arialb);
					el.setAttribute("role", "presentation");
				}
				return true;
			}
			return false
		}

		checkAndUpdateAccess = () => {
			let isCarousel = false;
			let time = 0;
			let maxTime = 1 * 60 * 5;
			let accessInterval = setInterval(() => {
				if (isCarousel || time > maxTime) clearInterval(accessInterval);
				const prevElements = document.querySelectorAll(".carousel-control-prev");
				this.updateCarousel(prevElements, "previous");
				const nextElements = document.querySelectorAll(".carousel-control-next");
				isCarousel = this.updateCarousel(nextElements, "next");
				time++;
			}, 1000);
		}
		//ACCESSIBILITY END


		componentDidMount() {
			store.searchStore.loadLanes();

			//ACCESSBILITY
			this.checkAndUpdateAccess();
		}

		renderSlide = (clips, index) => {
			return (
				<Carousel.Item key={index}>
					<Row >
						{clips}
					</Row>
				</Carousel.Item>
			);
		}

		renderCarousel = (c) => {

			const slides = [];

			const clips = [...c.getClips().elements];
			let cliplist = [...clips];
			let elms = [];
			let clip;

			const controls = cliplist.length > 3 ? true : false;

			if (cliplist.length < 3) {
				// single slide
				for (let i = 0; i < cliplist.length; i++) {
					clip = cliplist.shift();
					elms.push(
						<Clip
							key={""+c.getId()+clip.id}
							data={clip}
							ids={[]} />
					);
				}

				slides.push(this.renderSlide([...elms],c.getId()));
				elms = [];
			} else {
                let count = 1;
				// multiple lanes filled up to always show 3 in a row
				while (cliplist.length) {
					if (cliplist.length < 3) {
						cliplist = [...cliplist, ...clips];
					}

					for (let i = 0; i < 3; i++) {
						clip = cliplist.shift();
						elms.push(
							<Clip
								key={""+c.getId()+clip.id}
								data={clip}
								ids={[]} />
						);
					}

					slides.push(this.renderSlide([...elms],""+c.getId()+count));
					elms = [];
                    count++;
				}
			}


			return (
				<Carousel
					indicators={false}
					nextLabel={""}
					prevLabel={""}
					controls={controls}
					interval={300000}>

					{slides}

				</Carousel>

			);
		}

		getTitle = (lane) => {
			const metadata = lane.getMetadata();
			if (!metadata) {
				return lane.name;
			}

			if (window._LANG_ === _LANG_EN_) {
				return (metadata.fields.title_en && metadata.fields.title_en) || (metadata.fields.title && metadata.fields.title) || lane.name;
			}
			if (window._LANG_ === _LANG_CY_) {
				return (metadata.fields.title && metadata.fields.title) || (metadata.fields.title_en && metadata.fields.title_en) || lane.name;
			}

			return lane.name;
		}

		renderLaneHeader = (lane) => {
			const title = this.getTitle(lane);
			return (
				<Row className={["Section", "Header"].join(' ')}>
					<Col>
						<h1>{title}</h1>
					</Col>
				</Row>
			);
		}

		renderLanes = () => {
			const lanes = store.searchStore.getLanes();
			if (!Object.keys(lanes).length) {
				return "";
			}

			const out = [];

			for (let key in lanes) {
				out.push(
					<div key={key}>
						{this.renderLaneHeader(lanes[key])}
						{this.renderCarousel(lanes[key])}
					</div>
				);
			}

			return out;
		}


		render() {
			return this.renderLanes();
		}
	}
)

export default Flow.injectFlow(injectIntl(Lanes));

import  { Row, Col, Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import DateFilter, { createFromDate, createToDate } from './DateFilter';

import { format } from 'date-fns';

import './Filter.scss';

const Broadcast = observer(
    class Broadcast extends Component {
      
        state = {
            field: 'broadcast_at'
        };
        
        constructor(props) {
            super(props);
        }

        render() {
            return (
                <DateFilter
                    { ...this.props }
                    field={this.state.field}
                    title={this.props.intl.formatMessage({id: "filter.broadcast"})} />
            );

        }
    }
)

export default injectIntl(Broadcast);

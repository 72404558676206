import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"

import { FormattedMessage } from 'react-intl'
import { Row, Col, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

import { VIEW_MODE_GRID, VIEW_MODE_LIST, SORTING_DIRECTION_ASC, SORTING_DIRECTION_DESC, SORTING_BY_RELEVANCE, SORTING_BY_DATE } from 'Config/constants';

import './DisplayOptions.scss';

import store from 'index';

const DisplayOptions = observer(
    class DisplayOptions extends Component {

        state = {}

        uiStore = store.uiStore;
		
		renderDirection = () => {
			if(this.uiStore.getSortingBy() === SORTING_BY_RELEVANCE) {
				return (
					<Dropdown.Item onClick={() => this.uiStore.setSorting(SORTING_DIRECTION_DESC)/*this.props.setSorting(SORTING_DIRECTION_DESC)*/}>
						<FontAwesomeIcon icon={this.uiStore.getSorting()/*this.props.sorting*/ === SORTING_DIRECTION_DESC ? faCheckCircle : faCircle} /> <FormattedMessage id={"common.descending"} />
					</Dropdown.Item>
				);
			}
			
			return (
				<>
					<Dropdown.Item onClick={() => this.uiStore.setSorting(SORTING_DIRECTION_ASC) /*this.props.setSorting(SORTING_DIRECTION_ASC)*/}>
						<FontAwesomeIcon icon={this.uiStore.getSorting()/*this.props.sorting*/ === SORTING_DIRECTION_ASC ? faCheckCircle : faCircle} /> <FormattedMessage id={"common.ascending"} />
					</Dropdown.Item>
					<Dropdown.Item onClick={() => this.uiStore.setSorting(SORTING_DIRECTION_DESC)/*this.props.setSorting(SORTING_DIRECTION_DESC)*/}>
						<FontAwesomeIcon icon={this.uiStore.getSorting()/*this.props.sorting*/ === SORTING_DIRECTION_DESC ? faCheckCircle : faCircle} /> <FormattedMessage id={"common.descending"} />
					</Dropdown.Item>
				</>
			);
		}

        render() {
            return (
                <Dropdown className="d-inline mx-2 DisplayOptions" align="end" autoClose="outside"
                    aria-label="display options" tabIndex="0">
                    {/* <Dropdown.Toggle id="dropdown-autoclose-outside"> */}
                    <Dropdown.Toggle>
                        <FormattedMessage id={"search.toolbar.options"} defaultMessage="Display Options" />

                        <Dropdown.Menu className={"DisplayMenu"} aria-label="menu">
                            <Col>
                                <h4><FormattedMessage id={"common.sorting"} /></h4>
                                <Row>
                                    <Col>
                                        <Dropdown.Item onClick={() => this.uiStore.setSortingBy(SORTING_BY_RELEVANCE)}>
                                            <FontAwesomeIcon icon={this.uiStore.getSortingBy() === SORTING_BY_RELEVANCE ? faCheckCircle : faCircle} /> <FormattedMessage id={"search.toolbar.sorting.relevance"} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.uiStore.setSortingBy(SORTING_BY_DATE)}>
                                            <FontAwesomeIcon icon={this.uiStore.getSortingBy() === SORTING_BY_DATE ? faCheckCircle : faCircle} /> <FormattedMessage id={"search.toolbar.sorting.date"} />
                                        </Dropdown.Item>
                                    </Col>
                                    <Col>
									{ this.renderDirection() }
                                    </Col>
                                </Row>
                                <hr />
                            </Col>
                            <Col>
                                <h4><FormattedMessage id={"common.results"} /></h4>
                                <Row>
                                    <Col>
                                        <Dropdown.Item onClick={() => this.uiStore.setResults(12)/*this.props.setResults(12)*/}>
                                            <FontAwesomeIcon icon={this.uiStore.getResults()/*this.props.results*/ === 12 ? faCheckCircle : faCircle} /> 12 <FormattedMessage id={"common.results"} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.uiStore.setResults(24)/*this.props.setResults(24)*/}>
                                            <FontAwesomeIcon icon={this.uiStore.getResults()/*this.props.results*/ === 24 ? faCheckCircle : faCircle} /> 24 <FormattedMessage id={"common.results"} />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.uiStore.setResults(48)/*this.props.setResults(48)*/}>
                                            <FontAwesomeIcon icon={this.uiStore.getResults()/*this.props.results*/ === 48 ? faCheckCircle : faCircle} /> 48 <FormattedMessage id={"common.results"} />
                                        </Dropdown.Item>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Col>
                        </Dropdown.Menu>
                    </Dropdown.Toggle>
                </Dropdown>
            );
        }
    }
)

export default Flow.injectFlow(DisplayOptions);

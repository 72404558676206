import { makeAutoObservable, runInAction } from "mobx";
import { SORTING_BY_DATE, CONTENT_TYPES, PROJECT_IDS } from 'Config/constants';
import { extendFulltextSearch } from 'Util/Search';

import { createURL } from "Util/URL";

export default class SearchStore {
    
    result = false;
    searching = false;
    rootStore = null;
    offset = 0;
    fulltext = "";
    within = "";
	output = "";
	
	terms = [];
	
	lanes = {};
    
    router = null;
    
    constructor(rootStore) {
        makeAutoObservable(this, {
            rootStore: false,
            router: false
        });
        this.rootStore = rootStore;
    }
    
    init = () => {
		this.initTerms();
    }

	initTerms = () => {
		const params = new URL(document.location).searchParams.getAll("terms");
        if(params.length) {
            this.terms = [ ...params ];
        }
		this.within = this.terms.join(' & ');
		this.output = this.terms.join(' & ');
	}
    
    setRouter = (router) => {
        this.router = router;
    }
    
    setFulltext = (fulltext = "") => {
        this.fulltext = fulltext;
    }
    
    getFulltext = () => {
        return this.fulltext;
    }
    
    setWithin = (within = "") => {
        this.within = within;
    }
    
    getWithin = () => {
        return this.within;
    }
    
    /*setSearch = (search = true) => {
        this.search = true;
    }*/
    
    getClips = () => {
        return (this.result && this.result.objects && this.result.objects.asArray()) || []; 
    }
    
    getOrder = () => {
        return (this.result && this.result.order) || [];
    }
    
    getCount = () => {
        return (this.result && parseInt(this.result.count)) || 0;
    }
    
    getOffset = () => {
        return (this.result && parseInt(this.result.offset)) || 0;
    }
    
    getLimit = () => {
        return (this.result && parseInt(this.result.limit)) || 0;
    }
	
	clear = () => {
		this.result = false;
		this.offset = 0;
		this.fulltext = "";
		this.within = "";
		this.output = "";
		this.terms = [];
	}
	
	onFulltextSearch = () => {
		const fulltext = this.fulltext.trim();
		if(fulltext) {
			this.terms.push(fulltext);			
		}
		this.fulltext = "";
		this.within = this.terms.join(' & ');
		this.output = this.terms.join(' & ');
		this.search();
	}
	
	onWithinSearch = () => {
		const within = this.within.trim();
		let terms = within.split(' & ');
		
		terms = terms.filter(term => {
			return term !== "";
		});
		
		terms = terms.map(term => {
			let t = term.trim();
			t = t.replace(/^& /, '').replace(/ &$/, '');
			return t;
		});
	
		this.terms = [ ...terms ];
		this.within = this.terms.join(' & ');
		this.output = this.terms.join(' & ');
		this.search();
	}
	
	/*
	_createFulltextSearch = () => {
		const keyword = [];
		const fulltext = this.fulltext && extendFulltextSearch({ keyword: this.fulltext }) || false;//keyword.push(this.fulltext);
        const within = this.within && extendFulltextSearch({ keyword: this.within }) || false;//keyword.push(this.within);
        
		if(fulltext && within) {
			keyword.push("+("+fulltext+")");
			keyword.push("+("+within+")");
		} else if(fulltext) {
			return fulltext;
		} else if(within) {
			return within;
		}
		
        if(keyword.length) {
			return keyword.join(' ');
            //return extendFulltextSearch({ keyword: keyword.join(' ') });
        }
		
		return "";
	}
	*/
	createFulltextSearch = () => {
		const keyword = [];
		
		this.terms.forEach(term => {
			const fulltext = extendFulltextSearch({ keyword: term });
			keyword.push("+("+fulltext+")");
		});
		
        if(keyword.length) {
			return keyword.join(' ');
        }
		
		return "";
	}
	
	checkSearch = () => {
		if(!this.result && !this.searching) {
			this.search();
		}
	}
    
    search = (path = false) => {
		if(this.rootStore.initStore) {
			return false;
		}
		
		// prevent searching on home
		if(document.location.pathname === "/") {
			return false;
		}
		
		this.searching = true;
		
        const fulltext = this.createFulltextSearch();
        const filter = this.getFilter();
        const results = this.rootStore.uiStore.getResults();
        const sorting = this.rootStore.uiStore.getSorting();
		const sortingBy = this.rootStore.uiStore.getSortingBy();
        const offset = this.rootStore.paginationStore.page * results;
		
		const fields = [ ...filter ];
		if(sortingBy === SORTING_BY_DATE) {
			fields.push({field:"broadcast_at",type:"ORDER",value: sorting});
		}
        
        window.flow.getClipsByFulltext(fulltext, {limit: results, offset: offset, fields:[
            ...fields
        ]}).then(res => {
            runInAction(() => {
                this.result = res;
                this.searching = false;
            });
            this.rootStore.paginationStore.setCount(this.getCount());
            
            this.router.history.replace({ pathname: document.location.pathname, search: this.createUrl() });
        });
        
        this.rootStore.groupSearchStore.search();
    }
    
    createUrl = () => {
        return createURL({
			user: this.rootStore.accountStore.user,
            filter: this.rootStore.filterStore.getAllFilter(), 
			terms: this.terms,
            fulltext: this.fulltext, 
            within: this.within,
            page: this.rootStore.paginationStore.page,
            sorting: this.rootStore.uiStore.sorting,
            results: this.rootStore.uiStore.results,
            viewmode: this.rootStore.uiStore.viewmode,
            index: this.rootStore.accountStore.index
        })
    }
    
    getFilter = () => {
        const allFilter = this.rootStore.filterStore.getAllFilter();
        const keys = Object.keys(allFilter);
        const filter = [];
        
        keys.forEach(key => {
            let values = allFilter[key].getSearch() || [];
            values.forEach(value => {
                filter.push(value);
            });
        });
        
        return filter;
    }
	
	loadLanes = () => {
		window.flow.distprojectGetFullTree('pmdb_newstv', 0).then(res => {
			res.children.forEach(child => {
				if(child.project.name === "NLW Public Frontend" && child.children) {
					child.children.forEach(lane => {
						let id  = lane.project.getId();
						window.flow.getDistprojectById(id).then(res => {
							if(res.getCount()) {
                                runInAction(() => {
                                    this.lanes[id] = res;
                                });
							} else {
								// NO COUNT
							}
						}).catch(res => {});
					});
				}
			});
		}).catch(res => {
			
		});
	}
	
	getLanes = () => {
		return this.lanes;
	}
	
	loadContent = () => {
        CONTENT_TYPES.forEach(type => {
            window.flow.getComments({ type: type, project: PROJECT_IDS[process.env.REACT_APP_SERVER] }).then(res => {
                this.rootStore.uiStore.setContent(type, res);
            }).catch(res => {
                
            });
        });
    }
	
	replaceUrl = () => {
		this.router.history.replace({ pathname: document.location.pathname, search: this.createUrl() });
	}
}


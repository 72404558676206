export const clip = [
    {
        "type": "tab",
        "name": "metadata.tab.masterdata",
        "params": {}
    },
    {
        "name": "ID",
        "intl": "metadata.clip.id",
        "field": "id",
        "tab": "metadata.tab.masterdata",
        "order": 1,
        "search": true,
        "params": {
            "readonly": true
        },
        "type": "text"
    },
    {
        "name": "Status",
        "intl": "metadata.clip.status",
        "field": "status",
        "tab": "metadata.tab.masterdata",
        "order": 2,
        "search": true,
        "upload": false,
        "params": [],
        "type": "dropdown",
        "options": [
            "0= ",
            "published_public=Published - Public",
            "published_internal=Published - Internal",
            "awaiting_approval_manual_upload=Awaiting Approval - Manual Upload",
            "awaiting_approval_general_ingest=Awaiting Approval - General Ingest (Default)",
            "awaiting_approval_rights=Awaiting Approval - Rights",
            "incomplete_metadata_issue=Incomplete - Metadata issue",
            "restricted_no_playback=Restricted - No Playback",
            "restricted_limited_metadata=Restricted - Limited Metadata",
            "oofline_immediate_take_down=Offline - Immediate take-down"
        ]
    },
    {
        "name": "Title",
        "intl": "metadata.clip.title",
        "field": "title",
        "tab": "metadata.tab.masterdata",
        "order": 4,
        "upload": true,
        "search": true,
        "params": [],
        "type": "text"
    },

    {
        "name": "Alternative Title",
        "intl": "metadata.clip.alternativetitle",
        "field": "alternativetitle",
        "tab": "metadata.tab.masterdata",
        "order": 7,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Summary",
        "intl": "metadata.clip.summary",
        "field": "summary",
        "tab": "metadata.tab.masterdata",
        "order": 8,
        "search": true,
        "params": [],
        "type": "textfield"
    },
    {
        "name": "Annotations",
        "intl": "metadata.clip.annotations",
        "field": "subtitle",
        "tab": "metadata.tab.masterdata",
        "order": 9,
        "search": true,
        "params": {
            "hidden": true
        },
        "type": "textfield"
    },
    {
        "name": "Shot List",
        "intl": "metadata.clip.shortlist_internal",
        "field": "shotlist_internal",
        "tab": "metadata.tab.masterdata",
        "order": 10,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Genre",
        "intl": "metadata.clip.genre",
        "field": "genre",
        "tab": "metadata.tab.masterdata",
        "order": 11,
        "upload": true,
        "search": true,
        "params": [],
        "type": "dropdown",
        "options": [
            "= ",
            "Children's television programs=Children's television programs",
            "Cultural television programs=Cultural television programs",
            "Documentary television programs=Documentary television programs",
            "Drama=Drama",
            "Educational television programs=Educational television programs",
            "Music=Music",
            "Nonfiction television programs=Nonfiction television programs",
            "Radio programs=Radio programs",
            "Sports television programs=Sports television programs",
            "Televised sports events=Televised sports events",
            "Television comedies=Television comedies",
            "Television interviews=Television interviews",
            "Television news programs=Television news programs",
            "Television programs=Television programs",
            "Television quiz show=Television quiz show",
            "Television talk shows=Television talk shows",
            "Variety shows (Television programs)=Variety shows (Television programs)"
        ]
    },

    {
        "name": "Production date",
        "intl": "metadata.clip.production_date",
        "field": "production_date",
        "tab": "metadata.tab.masterdata",
        "order": 13,
        "upload": true,
        "search": true,
        "params": {
            "placeholder": "YYYY/MM/DD"
        },
        "type": "text"
    }, {
        "name": "Broadcast at",
        "intl": "metadata.clip.broadcast_at",
        "field": "broadcast_at",
        "tab": "metadata.tab.masterdata",
        "order": 12,
        "search": true,
        "params": {
            "datefield": true
        },
        "type": "datetime"
    },
    {
        "name": "Language",
        "intl": "metadata.clip.language",
        "field": "language",
        "tab": "metadata.tab.masterdata",
        "order": 14,
        "upload": true,
        "search": true,
        "params": [],
        "params": {
            "valueField": "value",
            "labelField": "displayValue"
        },
        "type": "addlist",
        "options": [
            "=",
            "English=English",
            "Welsh=Welsh",
            "No linguistic content=No linguistic content",
            "Other=Other"
        ]
    },

    {
        "name": "Subjects",
        "intl": "metadata.clip.subjects",
        "field": "subjects",
        "tab": "metadata.tab.masterdata",
        "order": 15,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Location",
        "intl": "metadata.clip.location",
        "field": "location",
        "tab": "metadata.tab.masterdata",
        "order": 16,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Keywords",
        "intl": "metadata.clip.keywords",
        "field": "keywords",
        "tab": "metadata.tab.masterdata",
        "order": 17,
        "search": true,
        "params": [],
        "type": "textfield"
    },
    {
        "name": "Rights",
        "intl": "metadata.clip.rights",
        "field": "rights",
        "tab": "metadata.tab.masterdata",
        "order": 18,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Note(s)",
        "intl": "metadata.clip.notes",
        "field": "notes",
        "tab": "metadata.tab.masterdata",
        "order": 19,
        "search": true,
        "params": {},
        "type": "textfield"
    },
    {
        "name": "Content Type",
        "intl": "metadata.clip.contenttyp",
        "field": "contenttype",
        "tab": "metadata.tab.masterdata",
        "order": 20,
        "search": true,
        "upload": true,
        "params": [],
        "type": "dropdown",
        "options": [
            "= ",
            "Broadcast material=Broadcast material",
            "Unedited Broadcast material=Unedited Broadcast material",
            "Non-broadcast material=Non-broadcast material",
            "Unknown=Unknown"
        ]
    },
    {
        "name": "Content Alert",
        "intl": "metadata.clip.contentAlert",
        "field": "contentalert",
        "tab": "metadata.tab.masterdata",
        "order": 21,
        "search": true,
        "params": {
            "valueField": "value",
            "labelField": "displayValue"
        },
        "type": "addlist",
        "options": [
            "No=No",
            "Programme contains adult themes=Programme contains adult themes",
            "Programme contains sensitive material=Programme contains sensitive material",
            "Programme contains some medical themes=Programme contains some medical themes",
            "Programme contains strong language=Programme contains strong language",
            "Programme contains upsetting scenes=Programme contains upsetting scenes",
            "Programme contains nudity=Programme contains nudity",
            "Unknown=Unknown"
        ]
    },
    {
        "name": "Container Types",
        "intl": "metadata.clip.contenttype",
        "field": "containertype",
        "tab": "metadata.tab.masterdata",
        "order": 21,
        "search": true,
        "upload": true,
        "params": {
            "reload": "series",
        },
        "type": "dropdown",
        "options": ["= ", "series_program=Parent Series", "series_episode=Episode", "series_series=Program", "series_season=Series"]
    },
    {
        "name": "Episode Title",
        "intl": "metadata.clip.episodetitle",
        "field": "episodetitle",
        "tab": "metadata.tab.masterdata",
        "order": 6,
        "upload": false,
        "search": true,
        "params": {
            "condition": {
                "containertype": "series_episode"
            }
        },
        "type": "text"
    }, {
        "name": "Series Number",
        "intl": "metadata.clip.seriesnumber",
        "field": "seriesnumber",
        "tab": "metadata.tab.masterdata",
        "order": 5,
        "upload": false,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Series Title",
        "intl": "metadata.clip.seriestitle",
        "field": "seriestitle",
        "tab": "metadata.tab.masterdata",
        "order": 5,
        "upload": false,
        "search": true,
        "params": {
            "condition": {
                "containertype": "series_series"
            }
        },
        "type": "text"
    },
    {
        "name": "Episode Number",
        "intl": "metadata.clip.episodenumber",
        "field": "episodenumber",
        "tab": "metadata.tab.masterdata",
        "order": 6,
        "upload": false,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "type": "tab",
        "name": "metadata.tab.responsibility",
        "params": {}
    },
    {
        "name": "Channel Name",
        "intl": "metadata.clip.channelname",
        "field": "channelname",
        "tab": "metadata.tab.responsibility",
        "order": 1,
        "upload": true,
        "search": true,
        "params": {},
        "type": "dropdown",
        "options": [
            "= ",
            "BBC 2W=BBC 2W",
            "BBC One=BBC One",
            "BBC One Wales=BBC One Wales",
            "BBC Two=BBC Two",
            "BBC Two Wales=BBC Two Wales",
            "BBC Three=BBC Three",
            "BBC Four=BBC Four",
            "BBC Choice=BBC Choice",
            "BBC Parliament=BBC Parliament",
            "BBC Radio 1=BBC Radio 1",
            "BBC Radio 2=BBC Radio 2",
            "BBC Radio 3=BBC Radio 3",
            "BBC Radio 4=BBC Radio 4",
            "BBC Radio 5 Live=BBC Radio 5 Live",
            "BBC Radio 5 Live Sports Extra=BBC Radio 5 Live Sports Extra",
            "BBC Radio 6 Music=BBC Radio 6 Music",
            "BBC Radio 7=BBC Radio 7",
            "BBC Radio Cymru=BBC Radio Cymru",
            "BBC Radio Wales=BBC Radio Wales",
            "Channel 5=Channel 5",
            "Channel Four=Channel Four",
            "Five=Five",
            "ITV1=ITV1",
            "ITV1 Wales=ITV1 Wales",
            "Radio Ceredigion=Radio Ceredigion",
            "S4C=S4C",
            "S4C2=S4C2",
            "S4C Digidol=S4C Digidol"
        ]
    },
    {
        "name": "Creator",
        "intl": "metadata.clip.creator",
        "field": "creator",
        "tab": "metadata.tab.responsibility",
        "order": 2,
        "upload": true,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Production Company",
        "intl": "metadata.clip.productioncompany",
        "field": "productioncompany",
        "tab": "metadata.tab.responsibility",
        "order": 3,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Producer",
        "intl": "metadata.clip.producer",
        "field": "producer",
        "tab": "metadata.tab.responsibility",
        "order": 4,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Director",
        "intl": "metadata.clip.director",
        "field": "director",
        "tab": "metadata.tab.responsibility",
        "order": 5,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Editor",
        "intl": "metadata.clip.editor",
        "field": "editor",
        "tab": "metadata.tab.responsibility",
        "order": 6,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Other Contributors",
        "intl": "metadata.clip.othercontributors",
        "field": "othercontributors",
        "tab": "metadata.tab.responsibility",
        "order": 7,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Performer",
        "intl": "metadata.clip.performers",
        "field": "performers",
        "tab": "metadata.tab.responsibility",
        "order": 8,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "External Links",
        "intl": "metadata.clip.externallinks",
        "field": "externallinks",
        "tab": "metadata.tab.responsibility",
        "order": 13,
        "params": {
            "short": true
        },
        "type": "linkaddlist"
    },
    {
        "type": "tab",
        "name": "metadata.tab.reference",
        "params": {}
    },
    {
        "name": "NLW filename",
        "intl": "metadata.clip.identifier",
        "field": "identifier",
        "tab": "metadata.tab.reference",
        "order": 1,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Original Filename",
        "intl": "metadata.clip.originalfilename",
        "field": "originalfilename",
        "tab": "metadata.tab.reference",
        "order": 2,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "NLW ID",
        "intl": "metadata.clip.nlwid",
        "field": "nlwid",
        "tab": "metadata.tab.reference",
        "order": 3,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Fedora ID",
        "intl": "metadata.clip.fedoraid",
        "field": "fedoraid",
        "tab": "metadata.tab.reference",
        "order": 4,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Series ID",
        "intl": "metadata.clip.seriescrid",
        "field": "seriescrid",
        "tab": "metadata.tab.reference",
        "order": 5,
        "upload": false,
        "search": true,
        "params": [],
        "type": "linkaddlist"
    },
    {
        "name": "Episode ID",
        "intl": "metadata.clip.episodecrid",
        "field": "episodecrid",
        "tab": "metadata.tab.reference",
        "order": 6,
        "search": true,
        "params": {
            "short": true
        },
        "type": "linkaddlist"
    },
    {
        "name": "BBC Programme ID",
        "intl": "metadata.clip.bbcprogrammid",
        "field": "bbcprogrammeid",
        "tab": "metadata.tab.reference",
        "order": 7,
        "search": true,
        "upload": false,
        "params": [],
        "type": "text"
    },
    ,
    {
        "name": "Parent Series",
        "intl": "metadata.clip.parentseries",
        "field": "_series_parent_program",
        "tab": "metadata.tab.reference",
        "order": 7,
        "search": true,
        "upload": false,
        "params": {
            "condition": {
                "containertype": "series_season"
            }
        },
        "type": "text"
    },
    {
        "name": "Other IDs",
        "intl": "metadata.clip.bbcotherids",
        "field": "bbcotherids",
        "tab": "metadata.tab.reference",
        "order": 8,
        "upload": false,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Source ID",
        "intl": "metadata.clip.itvid",
        "field": "itvid",
        "tab": "metadata.tab.reference",
        "order": 9,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Program or Parent Series",
        "intl": "metadata.clip.programorparentseries",
        "field": "programorseries",
        "tab": "metadata.tab.reference",
        "order": 10,
        "upload": false,
        "search": true,
        "params": {
            "condition": {
                "containertype": "series_episode"
            }
        },
        "type": "text"
    },
    {
        "name": "Season ID",
        "intl": "metadata.clip.seasonid",
        "field": "_series_parent",
        "tab": "metadata.tab.reference",
        "order": 10,
        "upload": false,
        "search": true,
        "params": {
            "condition": {
                "programorseries": "Parent Series"
            },
            "conditionext": {
                "containertype": "series_episode"
            }
        },
        "type": "text"
    },
    {
        "name": "Source",
        "intl": "metadata.clip.source",
        "field": "source",
        "tab": "metadata.tab.reference",
        "order": 11,
        "search": true,
        "params": [],
        "type": "dropdown",
        "options": [
            "= ",
            "BBC CYMRU HD=BBC CYMRU HD",
            "BBC CYMRU WIWO=BBC CYMRU WIWO",
            "BBC CYMRU PYC=BBC CYMRU PYC",
            "BBC CYMRU Drama=BBC CYMRU Drama",
            "BBC CYMRU Tocyn Tymor=BBC CYMRU Tocyn Tymor",
            "BBC CYMRU FF Film=BBC CYMRU FF Film",
            "BBC CYMRU Scrum V=BBC CYMRU Scrum V",
            "OFFAIRDIGR=OFFAIRDIGR",
            "OFFAIRDIGV=OFFAIRDIGV"
        ]
    },
    {
        "name": "Still Image",
        "intl": "metadata.clip.stillimage",
        "field": "stillimage",
        "tab": "metadata.tab.reference",
        "order": 12,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Physical Media ID",
        "intl": "metadata.clip.physicalmediaid",
        "field": "physicalmediaid",
        "tab": "metadata.tab.reference",
        "order": 13,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "type": "tab",
        "name": "metadata.tab.media",
        "params": {}
    },
    {
        "name": "Duration",
        "intl": "metadata.clip.duration",
        "field": "duration",
        "tab": "metadata.tab.media",
        "order": 1,
        "search": true,
        "params": {
            'readonly': true
        },
        "type": "text"
    },
    {
        "name": "Aspect Ratio",
        "intl": "metadata.clip.aspectratio",
        "field": "aspectratio",
        "tab": "metadata.tab.media",
        "order": 2,
        "search": true,
        "params": [],
        "type": "text"
    },
    {
        "name": "Format",
        "intl": "metadata.clip.format",
        "field": "format",
        "tab": "metadata.tab.media",
        "order": 3,
        "search": true,
        "params": [],
        "type": "dropdown",
        "options": [
            "= ",
            "Radio=Radio",
            "TV=TV",
            "Video=Video",
            "Film=Film",
            "Unknown=Unknown"
        ]
    },
    {
        "name": "Colour",
        "intl": "metadata.clip.colour",
        "field": "colour",
        "tab": "metadata.tab.media",
        "order": 4,
        "search": true,
        "params": [],
        "type": "dropdown",
        "options": [
            "= ",
            "Black and White=Black and White",
            "Colour=Colour",
            "Mixed=Mixed"
        ]
    },
    {
        "name": "Sound",
        "intl": "metadata.clip.sound",
        "field": "sound",
        "tab": "metadata.tab.media",
        "order": 5,
        "search": true,
        "params": [],
        "type": "dropdown",
        "options": [
            "= ",
            "Sound=Sound",
            "Silent=Silent"
        ]
    },
    {
        "name": "Checksum",
        "intl": "metadata.clip.checksum",
        "field": "checksum",
        "tab": "metadata.tab.media",
        "order": 6,
        "search": true,
        "params": {
            "readonly": true
        },
        "type": "text"
    },
    {
        "name": "Ingest date",
        "intl": "metadata.clip.ingestdate",
        "field": "created",
        "tab": "metadata.tab.media",
        "order": 2,
        "search": true,
        "params": {
            "datefield": true,
            "readonly": true
        },
        "type": "text"
    },
    {
        "type": "tab",
        "name": "metadata.tab.relationships",
        "params": {}
    },
    {
        "name": "Same as",
        "intl": "metadata.clip.sameas",
        "field": "sameas",
        "tab": "metadata.tab.relationships",
        "order": 1,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Derived from",
        "intl": "metadata.clip.derivedfrom",
        "field": "derivedfrom",
        "tab": "metadata.tab.relationships",
        "order": 2,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Has derivative",
        "intl": "metadata.clip.hasderivative",
        "field": "hasderivative",
        "tab": "metadata.tab.relationships",
        "order": 3,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Is Part Of",
        "intl": "metadata.clip.ispartof",
        "field": "ispartof",
        "tab": "metadata.tab.relationships",
        "order": 4,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Has Part",
        "intl": "metadata.clip.haspart",
        "field": "haspart",
        "tab": "metadata.tab.relationships",
        "order": 5,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Is Version Of",
        "intl": "metadata.clip.isversionof",
        "field": "isversionof",
        "tab": "metadata.tab.relationships",
        "order": 6,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Has Version",
        "intl": "metadata.clip.hasversion",
        "field": "hasversion",
        "tab": "metadata.tab.relationships",
        "order": 7,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Link To Series",
        "intl": "metadata.clip.linktoseries",
        "field": "linktoseries",
        "tab": "metadata.tab.relationships",
        "order": 8,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Link to episode(from series)",
        "intl": "metadata.clip.linktoepisodes",
        "field": "linktoepisodes",
        "tab": "metadata.tab.relationships",
        "order": 9,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Next in Sequence",
        "intl": "metadata.clip.nextinsequence",
        "field": "nextinsequence",
        "tab": "metadata.tab.relationships",
        "order": 10,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    {
        "name": "Follows in Sequence",
        "intl": "metadata.clip.followsinsequence",
        "field": "followsinsequence",
        "tab": "metadata.tab.relationships",
        "order": 11,
        "search": true,
        "params": [],
        "type": "internallink"
    },
    ,
    {
        "type": "tab",
        "name": "metadata.tab.publishing",
        "params": {}
    },
    {
        "name": "Published Summary",
        "intl": "metadata.clip.publishedsummary",
        "field": "published_summary",
        "tab": "metadata.tab.publishing",
        "order": 2,
        "search": true,
        "params": [],
        "type": "textfield"
    },
    {
        "type": "tab",
        "name": "metadata.tab.history",
        "params": {}
    },
    {
        "name": "Uploaded by",
        "intl": "metadata.clip.uploaded_by",
        "field": "uploaded_by",
        "tab": "metadata.tab.history",
        "order": 1,
        "params": {
            "readonly": true
        },
        "type": "text"
    },
    {
        "name": "Last Changed",
        "intl": "metadata.clip.lastchange",
        "field": "lastchange",
        "tab": "metadata.tab.history",
        "order": 3,
        "search": true,
        "params": {
            "datefield": true,
            "readonly": true
        },
        "type": "text"
    },
    {
        "name": "Last Changed by",
        "intl": "metadata.clip.lastchangeby",
        "field": "lastchangeby",
        "tab": "metadata.tab.history",
        "order": 4,
        "search": true,
        "params": {
            "readonly": true
        },
        "type": "text"
    },
    {
        "type": "tab",
        "name": "metadata.tab.related",
        "params": {}
    },
    {
        "name": "Related Clips",
        "intl": "metadata.clip.relatedClips",
        "field": "shotlist_publish",
        "tab": "metadata.tab.related",
        "params": [],
        "type": "text"
    }
];

import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';

import { Row, Col, Spinner } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { FormattedMessage } from 'react-intl';

import Clip from 'Components/ClipGrid';
import Search from 'Components/Search';
import Lanes from 'Components/UI/Lanes/Lanes';

import image from 'Assets/img/home-image.png';

import { _LANG_EN_, _LANG_CY_, CONTENT_TYPE_SPLASH } from "Config/constants";

import './Home.scss';

import store from 'index';

const Home = observer(
    class Home extends Component {
        
        searchStore = store.searchStore;
        filterStore = store.filterStore;
    
        componentDidMount() {
            this.filterStore.clearFilter(false);
			this.searchStore.clear();
			window.scrollTo(0,0);
        }
		
		onSearch = () => {
			this.props.history.push({ pathname: '/search' });
			store.searchStore.onFulltextSearch();
        }
        
        renderText = () => {
            const content = store.uiStore.getContent(CONTENT_TYPE_SPLASH);
            if(!content) return "";
            
            let key = "sp_txt_"; 
            key += (window._LANG_ === _LANG_EN_) ? "en_" : "cy_";
            key += (process.env.REACT_APP_SERVER === "EXT") ? "ext" : "int";
            
            return (content[key] && content[key].comment && parse(content[key].comment)) || this.renderDefaultText();
        }
        
        renderDefaultText = () => {
            const linkIntro = this.props.intl.formatMessage({id: "page.home.link.intro" });
            const linkAvailableA = this.props.intl.formatMessage({id: "page.home.link.a.available" });
			const linkAvailableB = this.props.intl.formatMessage({id: "page.home.link.b.available" });
            
            return (
                <>
                <p>
                    <FormattedMessage 
                        id={(process.env.REACT_APP_SERVER === "EXT" && "page.home.text.intro.ext") || "page.home.text.intro"} 
                        values={{
                            b: msg => <strong>{msg}</strong>,
                            a: msg => <a href={linkIntro}>{msg}</a>
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage 
                        id={(process.env.REACT_APP_SERVER === "EXT" && "page.home.text.available.ext") || "page.home.text.available"} 
                        values={{
                            a: msg => <a href={linkAvailableA}>{msg}</a>,
                            b: msg => <a href={linkAvailableB}>{msg}</a>
                        }}
                    />
                </p>
                </>
            );
        }
        
        renderBackground = () => {
            const content = store.uiStore.getContent(CONTENT_TYPE_SPLASH);
            if(!content) return "";
                      
            let key = "sp_img";
            
            console.log(content[key]);
            
            return (content[key] && content[key].comment && parse(content[key].comment)) || this.renderDefaultBackground();
        }
        
        renderDefaultBackground = () => {
            const linkIntro = this.props.intl.formatMessage({id: "page.home.link.intro" });
            const linkAvailableA = this.props.intl.formatMessage({id: "page.home.link.a.available" });
			const linkAvailableB = this.props.intl.formatMessage({id: "page.home.link.b.available" });
            
            return (
                <p>
                    <img src={image} />
                </p>
            );
        }

        render() {
            return (
                <Col className={"HomePage"} >
                    <Row className={"Splash"}>
                        <div className={"Background"}>{this.renderBackground()}</div>
                        <Col>
                            <Row>
                                <Col md={3} className={"no-mobile"}></Col>
                                <Col md={6}>
                                    <div className={"SearchContainer"}>
                                        <Search 
                                            {...this.props} 
                                            setValue={this.searchStore.setFulltext}
                                            getValue={this.searchStore.getFulltext}
                                            placeholder={"search.input.clip"}
											onSearch={this.onSearch}
                                            field={"fulltext"} />
                                    </div>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                            <div className={"Overlay"}>
                                { this.renderText() }
                            </div>
                        </Col>
                    </Row>
					
					<Lanes />
                    
                </Col>
            );
        }
    }
)

export default withRouter(Flow.injectFlow(injectIntl(Home)));

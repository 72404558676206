import  { Row, Col, Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import DateFilter from './DateFilter';

import { createFromDate, createToDate, splitDate } from "Util/Date.js";

import './Filter.scss';

const Production = observer(
    class Production extends Component {
		
		defaultDates = {
			from: { day: "", month: "", year: "" },
			to: { day: "", month: "", year: "" },
		};
      
        state = {
            field: 'production_date'
        };
        
        constructor(props) {
            super(props);
        }

        createSearch = (filter) => {
			let dates = { ...this.defaultDates };
			
			if(Array.isArray(filter) && filter.length) {
				dates =  splitDate(filter[0]);
				
			} else if(filter.from && filter.to) {
				dates.from = filter.from;
				dates.to = filter.to;
			}
			
            const search = [];
            const from = createFromDate(dates.from);
            const to = createToDate(dates.to);
			/*
            const search = [];
            const from = createFromDate(filter.from);
            const to = createToDate(filter.to);
            */
            if(from && to) {
                //search.push({field: "", type: "BEGIN_AND", value: ""});
                search.push({field: 'production_date_start', type:">=", value: from});
                search.push({field: 'production_date_stop', type:"<=", value: to});
                //search.push({field: "", type: "END_AND", value: ""});
            } else if(from) {
                search.push({field: 'production_date_start', type:">=", value: from});
            } else if(to) {
                search.push({field: 'production_date_stop', type:"<=", value: to});
            }
            
            return search;
        }

        render() {
            return (
                <DateFilter
                    { ...this.props }
                    field={this.state.field}
                    title={this.props.intl.formatMessage({id: "filter.production"})}
                    createSearch={this.createSearch} />
            );

        }
    }
)

export default injectIntl(Production);

/*
const Production = observer(
    class Production extends Component {
      
        state = {
            filter: {},
            field: 'production_date',
            from: { day: "", month: "", year: "" },
            to: { day: "", month: "", year: "" },
            open: false
        };
        
        constructor(props) {
            super(props);
            this.state.open = props.open || false;
            this.state.filter = (props.filter && props.filter[this.state.field] && props.filter[this.state.field].filter) || {};
        }
        
        static getDerivedStateFromProps(props, state) {
            // TODO: refactor - always a different object
            //if(!Object.is(props.filter, state.filter)) {
              //  return {
                //    filter: (props.filter && props.filter[state.field] && props.filter[state.field].filter) || {}
               // }
            //}
            return null;
        }
        
        componentDidUpdate(prevProps, prevState, snapshot) {
            const stateAllEmpty = this.allEmpty(this.state.to) && this.allEmpty(this.state.from);
            const prevStateAllEmpty = this.allEmpty(prevState.to) && this.allEmpty(prevState.from);
            if(stateAllEmpty && !prevStateAllEmpty) {
                this.onSearch();
            }
        }
        
        toggle = () => {
            this.setState({ open: !this.state.open });
        }

        onSearch = () => {
            const filter = { from: this.state.from, to: this.state.to }
            const search = this.createSearch();
            
            this.setState({ filter: filter });
            this.props.setFilter({ [this.state.field]: { filter: filter, search: search } });
        }
        
        allEmpty = (date) => {
            return !date.day && !date.month && !date.year;
        }
        
        createFromDate = (dateFormat) => {
            const date = this.state.from;
            if(!date.year || this.allEmpty(date)) {
                return "";
            }
            
            if(!date.month) {
                return this.formatDate(new Date(date.year, 0, 1), dateFormat);
            }
            
            if(!date.day) {
                return this.formatDate(new Date(date.year, date.month - 1, 1), dateFormat);
            }
            
            return this.formatDate(new Date(date.year, date.month - 1, date.day), dateFormat);
        }
        
        createToDate = (dateFormat) => {
            const date = this.state.to;
            if(!date.year || this.allEmpty(date) ) {
                //return this.formatDate(new Date(), dateFormat);
                return "";
            }
            
            if(!date.month) {
                return this.formatDate(new Date(date.year, 12, 0), dateFormat);
            }
            
            if(!date.day) {
                return this.formatDate(new Date(date.year, date.month, 0), dateFormat);
            }
            
            return this.formatDate(new Date(date.year, date.month - 1, date.day), dateFormat);
        }
        
        formatDate = (date, dateFormat = 'yyyy-MM-dd') => {
            try {
                return format(date, dateFormat);
            } catch(ex) {
                return "";
            }
        }
        
        createSearch = (filter) => {
            const search = [];
            const from = this.createFromDate();
            const to = this.createToDate();
            
            if(from && to) {
                search.push({field: "", type: "BEGIN_AND", value: ""});
                search.push({field: 'production_date_start', type:">=", value: from});
                search.push({field: 'production_date_stop', type:"<=", value: to});
                search.push({field: "", type: "END_AND", value: ""});
            } else if(from) {
                search.push({field: 'production_date_start', type:">=", value: from});
            } else if(to) {
                search.push({field: 'production_date_stop', type:"<=", value: to});
            }
            
            return search;
        }
        
        renderInfo = () => {
            const output = [];
            const from = this.createFromDate('dd.MM.yyyy');
            const to = this.createToDate('dd.MM.yyyy');
            
            if(from) { output.push(<div><FormattedMessage id={"common.from"} />: {from}</div>); }
            if(to) { output.push(<div><FormattedMessage id={"common.to"} />: {to}</div>); }
            return output;
        }

        render() {
            const open = this.state.open ? <span className={"caret-up"} /> : <span className={"caret-down"} />;
            
            return (
                <Col className={"Filter"}>
                    <Row className={"Header"} onClick={this.toggle}>
                        <Col>
                            <div className={"control"}>
                                <h5><FormattedMessage id={"filter.production"} /></h5>
                                { open }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><Col className="Border"></Col></Col>
                    </Row>
                    <Row className={!this.state.open+""}>
                        <Col>{this.renderInfo()}</Col>
                    </Row>
                    <Row className={["filter-content", this.state.open].join(' ')}>
                        <Col>
                            <div className={"DateSearch"}>
                                <div className={"DateInput"}>
                                    <div><FormattedMessage id={"common.from"} />:</div>
                                    <Form.Control
                                        value={this.state.from.day}
                                        type="text" 
                                        placeholder="DD"
                                        maxlength={2}
                                        onChange={(evt) => this.setState({
                                            from: { ...this.state.from, day: evt.target.value }
                                        })}/>
                                    <Form.Control
                                        value={this.state.from.month}
                                        type="text" 
                                        placeholder="MM"
                                        maxlength={2}
                                        onChange={(evt) => this.setState({
                                            from: { ...this.state.from, month: evt.target.value }
                                        })}/>
                                    <Form.Control
                                        value={this.state.from.year}
                                        type="text"
                                        placeholder="YYYY"
                                        maxlength={4}
                                        onChange={(evt) => this.setState({
                                            from: { ...this.state.from, year: evt.target.value }
                                        })}/>
                                </div>
                                <div className={"DateInput"}>
                                    <div><FormattedMessage id={"common.to"} />:</div>
                                    <Form.Control
                                        value={this.state.to.day}
                                        type="text" 
                                        placeholder="DD"
                                        maxlength={2}
                                        onChange={(evt) => this.setState({
                                            to: { ...this.state.to, day: evt.target.value }
                                        })}/>
                                    <Form.Control
                                        value={this.state.to.month}
                                        type="text" 
                                        placeholder="MM"
                                        maxlength={2}
                                        onChange={(evt) => this.setState({
                                            to: { ...this.state.to, month: evt.target.value }
                                        })}/>
                                    <Form.Control
                                        value={this.state.to.year}
                                        type="text"
                                        placeholder="YYYY"
                                        maxlength={4}
                                        onChange={(evt) => this.setState({
                                            to: { ...this.state.to, year: evt.target.value }
                                        })}/>
                                </div>
                                <Button variant="primary" disabled={this.allEmpty(this.state.from) && this.allEmpty(this.state.to)} onClick={this.onSearch}>
                                    <FormattedMessage id={"common.search"} />
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Col>
            );
        }
    }
)

export default Production;
*/

import { makeAutoObservable, action, runInAction, reaction } from "mobx"

import { VIEW_MODE_GRID, SORTING_DIRECTION_DESC } from 'Config/constants';

import { createFromDate, createToDate, splitDate } from "Util/Date.js";

export default class GroupSearchStore {
    
    rootStore = null;
    
    facetCount = {};
	
	loading = false;
	
	active = ["media", "decade", "channelname", "language", "published"];
    
    constructor(rootStore) {
        makeAutoObservable(this, {
            rootStore: false, 
			active: false
        });
        this.rootStore = rootStore;
    }
    
    init = () => {
        
    }
	
	getMediaFacetCount = (cat) => {
		const options = ["video", "audio"];
		let group = {};
		
		options.forEach(option => {
			let includes = 0;
			if(Array.isArray(cat.group)) {
				cat.group.forEach(g => {
					if(g.attributes.value && g.attributes.value.indexOf(option) !== -1) {
						includes += parseInt(g.attributes.count);
					}
				});				
			} else {
				if(cat.group.attributes.value && cat.group.attributes.value.indexOf(option) !== -1) {
					includes += parseInt(cat.group.attributes.count);
				}
			}
			
			group[option] = includes;
		});
		
		return group;
	}
    
	/*
	* OLD VERSION
	*
    setFacetCount = (res) => {
        const count = {};
        res.forEach(cat => {
            if(cat.group) {
                let group = {};
                if(Array.isArray(cat.group)) {
					cat.group.forEach(g => {
                        if(g.attributes.value) {
                            group[g.attributes.value] = g.attributes.count;
                        }
                    });
				} else {
					if(cat.group.attributes.value) {
						group[cat.group.attributes.value] = cat.group.attributes.count;
					}
				}
				
				if(cat.attributes.field === "floor(year(air_date)/10)") {
					count.decade = group;
				} else if(cat.attributes.field === "media") {

					count[cat.attributes.field] = this.getMediaFacetCount(cat);
				} else {
					count[cat.attributes.field] = group;
				}
				
            }
        });
    }
	*/
	
	setSpecificCount = (key, cat) => {
		if(cat.group) {
			let group = {};
			if(Array.isArray(cat.group)) {
				cat.group.forEach(g => {
					if(g.attributes.value) {
						group[g.attributes.value] = g.attributes.count;
					}
				});
			} else {
				if(cat.group.attributes.value) {
					group[cat.group.attributes.value] = cat.group.attributes.count;
				}
			}
			
			if(cat.attributes.field === "floor(year(air_date)/10)") {
				this.facetCount.decade = group;
			} else if(cat.attributes.field === "media") {
				this.facetCount[cat.attributes.field] = this.getMediaFacetCount(cat);
			} else if(cat.attributes.field === "awards") {
				this.facetCount.published = group;
			} else {
				this.facetCount[cat.attributes.field] = group;
			}
		}
    }
    
    getGroupSearch = (theKey = false) => {
        const filter = [];
        const active = ["media", "decade", "channelname", "language", "published", "broadcast_at", "production_date"];
        const keys = Object.keys(this.rootStore.filterStore.getAllFilter());
        
        const realFilter = keys.filter(key => {
            return active.includes(key);
        });
        
        realFilter.forEach(key => {
			if(this.rootStore.filterStore.getAllFilter()[key].values.length || key === "published") {
				
				if(!theKey || (theKey && theKey !== key)) {
					
					switch(key) {
						case "decade":
							if(this.rootStore.filterStore.getAllFilter()[key].values.length) {
								let decades = this.rootStore.filterStore.getAllFilter()[key].values;
								let subs = decades.map(decade => {
									return decade.substring(0,3);
								});
								filter.push(
									{name: "search_floor(year(air_date)/10)", value: subs.join('|')}
								);
							}
							break;
						case "published":
							if(this.rootStore.filterStore.getAllFilter()[key].values.length) {
								filter.push(
									{name: "search_awards", value: this.rootStore.filterStore.getAllFilter()[key].values.join('|')}
								);
							} else {
								filter.push(
									{name: "search_awards", value: "published_internal|published_public"}
								);
							}
							break;
						case "media":
							if(this.rootStore.filterStore.getAllFilter()[key].values.length) {
								let medias = this.rootStore.filterStore.getAllFilter()[key].values;
								medias.forEach(media => {
									filter.push(
										{name: "contains_media", value: media}
									);
								});
							}
							break;
						case "broadcast_at":
							if(this.rootStore.filterStore.getAllFilter()[key].values.length) {
								let values = this.rootStore.filterStore.getAllFilter()[key].values[0];
								
								const dates = splitDate(values);
								const from = createFromDate(dates.from);
								const to = createToDate(dates.to);
								
								if(from) {
									filter.push({name: "datefrom_air_date", value: from});
								}
								
								if(to) {
									filter.push({name: "dateto_air_date", value: to});
								}
							}
							break;
						case "production_date":
							if(this.rootStore.filterStore.getAllFilter()[key].values.length) {
								let values = this.rootStore.filterStore.getAllFilter()[key].values[0];
								
								const dates = splitDate(values);
								const from = createFromDate(dates.from);
								const to = createToDate(dates.to);
								
								if(from) {
									filter.push({name: "datefrom_production_date_start", value: from});
								}
								
								if(to) {
									filter.push({name: "dateto_production_date_end", value: to});
								}
							}
							break;
						default:
							if(this.rootStore.filterStore.getAllFilter()[key].values.length) {
								filter.push(
									{name: "search_"+key, value: this.rootStore.filterStore.getAllFilter()[key].values.join('|')}
								);
							}
							break;
					}
					
				}
			}
        });
        return filter;
    }
	
	search = () => {
		this.loading = true;
		this.specificSearch();
    }
	
	/*
	* OLD VERSION
	*
	search = () => {
		this.loading = true;
        
		const groupSearch = this.getGroupSearch();

        window.flow.nlwPluginGroupsearchByFields([
			{name: 'groupfield', value: 'floor(year(air_date)/10)'},
			{name: 'groupfield', value: 'language'},
			{name: 'groupfield', value: 'channelname'},
			{name: 'groupfield', value: 'media'},
			{name: 'groupfield', value: 'awards'},
			...groupSearch,
			{name: 'fulltext', value: this.rootStore.searchStore.createFulltextSearch()}
		]).then(res => {
			this.loading = false;
		});
		
		this.specificSearch();
		
    }
	*/
	
	getFilter = (filter) => {
		switch(filter) {
			case "decade":
				return "floor(year(air_date)/10)";
			case "published":
				return "awards";
			default:
				return filter;
		}
	}
	
	specificSearch = () => {
		const active = ["media", "decade", "channelname", "language", "published"];
        const keys = Object.keys(this.rootStore.filterStore.getAllFilter());
        
        const realFilter = keys.filter(key => {
            return active.includes(key);
        });
		
		realFilter.forEach(filter => {
			let excluded = this.getGroupSearch(filter);
			window.flow.nlwPluginGroupsearchByFields([
				{name: 'groupfield', value: this.getFilter(filter)},
				...excluded,
				{name: 'fulltext', value: this.rootStore.searchStore.createFulltextSearch()}
			]).then(res => {
				this.setSpecificCount(filter, res.getData().get('category'));
			});
		});
	}
   
}


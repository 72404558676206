import { Row, Col, Form, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import React, { Component } from 'react';
import { observer } from "mobx-react"
import { injectIntl } from 'react-intl';
import store from 'index';

//import { makeAutoObservable, action, runInAction, reaction } from "mobx"
import { reaction, makeObservable, observable } from "mobx"

import './Filter.scss';

const FilterableInput = observer((props) => {
    if (!props.filterable) { return ""; }

    const placeholder = props.placeholder || "";


    return (
        <Col>
            <Form.Control
                aria-label="filter"
                value={props.keyword || ""}
                type="text"
                placeholder={placeholder}
                onChange={props.onChange} />
        </Col>
    );
});


const CheckboxFilter = observer(
    class CheckboxFilter extends Component {

        keyword = "";

        state = {
            keyword: "",
            open: false,
            data: false
        };

        content = React.createRef();

        store = store.filterStore;
        groupSearchStore = store.groupSearchStore;
        searchStore = store.searchStore;

        constructor(props) {
            super(props);
            makeObservable(this, {
                keyword: observable
            });

            this.store.registerFilter(this.props.field, this.props.createSearch || this.createSearch);

            this.state.data = props.data;
            this.state.field = this.props.field;
            this.state.open = props.open || false;

            reaction(() => this.store.getFilter(this.state.field).isClear(), this.clear);
            //autorun(() => this.clear());
        }

        static getDerivedStateFromProps(props, state) {
            return null;
        }

        static equals(arr1, arr2) {
            return (
                arr1.length === arr2.length &&
                arr1.every(function (elm, index) {
                    return elm === arr2[index];
                }));
        }

        //ACCESSIBILITY BEGIN
        onKeyUpHeader = (e) => {
            if (e.key === 'Enter') this.toggle();
        }
        //ACCESSIBILITY END

        componentDidMount = () => {
            
        }

        componentDidUpdate = (prevProps, prevState, snapshot) => {

        }

        clear = (isClear) => {
            if (isClear) {
                this.store.getFilter(this.state.field).cleared();
            }
        }

        toggle = () => {
            this.setState({ open: !this.state.open });
        }

        onChange = (id, value) => {
            let filter = [...this.store.getFilter(this.state.field).getValues()];
            let i = filter.indexOf(value);

            if (i !== -1) {
                filter.splice(i, 1);
            } else {
                filter.push(value);
            }

            filter = filter.sort();
            this.setFilter(filter);
            this.scrollUp();
        }

        setFilter = (filter) => {
            const search = (this.props.createSearch && this.props.createSearch(filter)) || this.createSearch(filter);
            this.store.getFilter(this.state.field).updateData({ filter: filter, search: search/*, createSearch: this.props.createSearch || this.createSearch*/ });
        }

        scrollUp = () => {
            if (this.content && this.content.current) {
                this.content.current.scrollTop = 0;
            }
        }

        createSearch = (filter) => {
            const search = [];

            if (filter.length) {
                search.push({ field: "", type: "BEGIN_OR", value: "" });

                filter.forEach(value => {
                    search.push({ field: this.state.field, type: "=", value: value });
                });

                search.push({ field: "", type: "END_OR", value: "" });
            }

            return search;
        }

        getChecked = () => {
            if (!this.state.data) { return []; }

            const filter = this.store.getFilter(this.state.field).getValues();
            const data = this.state.data.filter(item => {
                return filter.includes(item.value);
            });
            return data;
        }

        getNotChecked = () => {
            if (!this.state.data) { return []; }

            const filter = this.store.getFilter(this.state.field).getValues();
            const data = this.state.data.filter(item => {
                return !filter.includes(item.value);
            });
            return data;
        }

        getFiltered = (values) => {
            const keyword = this.store.getFilter(this.state.field).getKeyword();
            const data = values.filter(item => {
                if (!keyword) return true;
                return item.value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
            });
            return data;
        }

        getCount = (item) => {
            const checked = this.getChecked();
            if (this.props.getCount) {
                return this.props.getCount(item, this.groupSearchStore.facetCount, checked);
            }

            if (this.groupSearchStore.facetCount && this.groupSearchStore.facetCount[this.props.field] && this.groupSearchStore.facetCount[this.props.field][item.value]) {
                return parseInt(this.groupSearchStore.facetCount[this.props.field][item.value]);
            }

            return 0;
        }

        renderLoading = () => {
            return (
                <Spinner animation="border" variant="secondary" />
            );
        }

        renderOutput = (data, checked = false) => {
            const output = data.map((item, index) => {
                let id = item.value.replace(" ", "").toLowerCase();
                const tmp = this.getCount(item);
                let count = this.searchStore.searching ? this.renderLoading() : "(" + this.getCount(item) + ")";
                if (!checked && count === "(0)") {
                    return "";
                }

                return (
                    <Col className={"content-row"} key={id + checked} md={12}>
                        <Form.Check
                            aria-label={item.label || item.value || "cb filter"}
                            type={"checkbox"}
                            //id={id}
                            defaultChecked={checked}
                            label={item.label || item.value}
                            onChange={() => { this.onChange(id, item.value); }}
                        />
                        <span className={"facet-count"}>{count}</span>
                    </Col>
                );
            });
            return output;
        }

        // render input to filter checkbox list
        renderFilterable = () => {

            if (!this.props.filterable) { return ""; }

            const placeholder = this.props.placeholder || "";

            let value = this.store.getFilter(this.state.field).getKeyword();

            return (
                <Col>
                    <Form.Control
                        aria-label="filterable"
                        value={value || ""}
                        type="text"
                        placeholder={placeholder}
                        onChange={(evt) => { this.store.getFilter(this.state.field).setKeyword(evt.target.value); }} />
                </Col>
            );
        }

        renderFiltered = () => {
            const filtered = [];
            const filter = this.store.getFilter(this.state.field).getValues();
            this.state.data.forEach(item => {
                if (filter && filter.includes(item.value)) {
                    filtered.push(item.label || item.value);
                }
            });
            return filtered;
        }

        render() {
            const checked = this.renderOutput(this.getChecked(), true);
            const notChecked = this.renderOutput(this.getFiltered(this.getNotChecked()));
            const output = checked.concat(notChecked);

            const open = this.state.open ? <span className={"caret-up"} /> : <span className={"caret-down"} />;

            return (
                <Col className={"Filter"}>
                    <Row className={"Header"} onClick={this.toggle} tabIndex="0" onKeyUp={this.onKeyUpHeader}>
                        <Col>
                            <div className={"control"}>
                                <h5>{this.props.title}</h5>
                                {open}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><Col className="Border"></Col></Col>
                    </Row>
                    {/*<Row className={!this.state.open+""}>
                        <Col>{this.renderFiltered().join(', ')}</Col>
                    </Row>*/}
                    <Row ref={this.content} className={["filter-content", "searchable", this.state.open].join(' ')}>
                        {this.renderFilterable()}
                        {/*<FilterableInput 
                            {...this.props} 
                            keyword={this.keyword} 
                            onChange={(evt) => {this.keyword= evt.target.value }} />*/}
                        {output}
                    </Row>
                </Col>
            );
        }
    }
)

export default injectIntl(CheckboxFilter);

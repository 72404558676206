import { makeAutoObservable, action, runInAction, reaction } from "mobx"

import { VIEW_MODE_GRID, SORTING_DIRECTION_DESC, SORTING_BY_RELEVANCE } from 'Config/constants';

export default class UiStore {
    
    rootStore = null;
    
    results = 12;
    sorting = SORTING_DIRECTION_DESC;
	sortingBy = SORTING_BY_RELEVANCE;
    viewmode = VIEW_MODE_GRID;
    
    content = false;
    
    constructor(rootStore) {
        makeAutoObservable(this, {
            rootStore: false
        });
        this.rootStore = rootStore;
    }
    
    setSorting = (sorting) => {
        this.sorting = sorting;
        this.rootStore.searchStore.search();
    }
    
    getSorting = () => {
        return this.sorting;
    }
	
	setSortingBy = (sorting) => {
        this.sortingBy = sorting;
		if(sorting === SORTING_BY_RELEVANCE) {
			this.sorting = SORTING_DIRECTION_DESC;
		}
        this.rootStore.searchStore.search();
    }
    
    getSortingBy = () => {
        return this.sortingBy;
    }
    
    setResults = (results) => {
        if(results !== this.results) {
            let count = this.rootStore.paginationStore.page * this.results;
            let newPage = Math.floor(count / results);
            this.rootStore.paginationStore.changePage(newPage);
            this.results = results;
            this.rootStore.searchStore.search();
        }
    }
    
    getResults = () => {
        return this.results;
    }
    
    setViewMode = (viewmode) => {
        this.viewmode = viewmode;
        //this.rootStore.searchStore.search();
    }
    
    getViewMode = () => {
        return this.viewmode;
    }
    
    changeResults = (results) => {
        this.setResults(results);
        this.rootStore.searchStore.search();
    }
    
    setContent = (type, res) => {
        if(!this.content) {
            this.content = {};
        }
        
        if(res.comment && res.comment.elements) {
            this.content[type] = {};
            for(let key in res.comment.elements) {
                this.content[type][res.comment.elements[key].getName()] = res.comment.elements[key].data;
            }
        }
    }
    
    getContent = (type) => {
        return (this.content && this.content[type]) || false;
    }
    
    init = () => {
        this.initSorting();
        this.initResults();
        this.initViewMode();
        if(!this.content) {
            this.rootStore.searchStore.loadContent();
        }
    }
    
    initSorting = () => {
        const params = new URL(document.location).searchParams.getAll("sorting");
        if(params.length) {
            this.sorting = params[0];
        }
    }
    
    initResults = () => {
        const params = new URL(document.location).searchParams.getAll("results");
        if(params.length) {
            this.results = parseInt(params[0]);
        }
    }
    
    initViewMode = () => {
        const params = new URL(document.location).searchParams.getAll("viewmode");
        if(params.length) {
            this.viewmode = params[0];
        }
    }
}


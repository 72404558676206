export const extendFulltextSearch = (params) => {
    // TODO
    // replace ++ with + in loop
    // replace -- with - in loop
    /*while(params.keyword.indexOf('++') !== -1) {
        params.keyword = params.keyword.replace('++', '+');
    }*/
	let test = params.keyword.trim();
	const quotes = test.matchAll(/"/g);
	const indexes = [];
	const quoteStrings = [];
	
	for (const match of quotes) {
		indexes.push(match.index);
	}
	
	while(indexes.length) {
		let start = indexes.shift();
		let end = indexes.shift();
		
		if(Number.isInteger(start) && Number.isInteger(end)) {
			quoteStrings.push(test.slice(start, end+1));
		}
	}

	quoteStrings.forEach(str => {
		test = test.replace(str, "");
	});
	
	test = test.trim();

    let keywords = test.split(' ');
    if (keywords.length > 1) {
        //let first = "";
        keywords.forEach((keyword, index) => {
            if (keyword.length) {
                keywords[index] = getKeyword2(keyword);
                /*first = keyword.charAt(0);
                if (first !== '+' && first !== '-') {
                    keywords[index] = concatKeyword(keyword, '+');
                } else if (first === '+') {
                    keywords[index] = concatKeyword(keyword.substring(1), '+');
                } else if (first === '-') {
                    keywords[index] = concatKeyword(keyword.substring(1), '-');
                }*/
            }
        });
    } else if (keywords.length/* && hasSpecialChar(keywords[0])*/) {
        keywords[0] = getKeyword(keywords[0], false);//'"' + keywords[0] + '"';
    }
	
	keywords = keywords.concat(quoteStrings);

    return keywords.join(' ').trim();
}

/*
const concatKeyword = (keyword, operator) => {
    if (hasSpecialChar(keyword)) {
        return operator + '"' + keyword + '"';
    }
    return operator + keyword;
}
*/

const hasSpecialChar = (str) => {
    return str.match(/\W/) !== null;
}

const maskKeyword = (word) => {
    if (word.match(/\W/)) {
        return '"' + word + '"';
    }
    return word;
}

// build array [ operator, word, star ]
// 
const getKeyword = (word, concat = true) => {
    let test = word;
    const keyword = concat ? ["+", test, ""] : ["", test, ""];
    const operator = test.match(/^\+|-/);
    const star = test.match(/\*$/);

    // set +/- operator and extract substring
    if (operator) {
        test = test.slice(1);

        if (concat) {
            keyword[0] = operator[0];
        } else {
            // set - operator if only one term set
            if (operator[0] === "-") {
                keyword[0] = operator[0];
            }
        }
    }

    // set star operator and extract substring
    if (star) {
        test = test.slice(0, -1);
        keyword[2] = "*";
    }

    if (!test.length) {
        return "";
    }

    keyword[1] = maskKeyword(test);

    return keyword.join('');
}

const getKeyword2 = (word, concat = true) => {
    let test = word;
    const keyword = concat ? ["", test, ""] : ["", test, ""];
    const operator = test.match(/^\+|-/);
    const star = test.match(/\*$/);

    // set +/- operator and extract substring
    if (operator) {
        test = test.slice(1);

        if (concat) {
            keyword[0] = operator[0];
        } else {
            // set - operator if only one term set
            if (operator[0] === "-") {
                keyword[0] = operator[0];
            }
        }
    }

    // set star operator and extract substring
    if (star) {
        test = test.slice(0, -1);
        keyword[2] = "*";
    }

    if (!test.length) {
        return "";
    }

    keyword[1] = maskKeyword(test);

    return keyword.join('');
}

export const searchFullText = (params) => {
    if (params && params.keyword) {
        params.keyword = extendFulltextSearch(params);
    }
    
    window.flow.getClipsByFulltext(params.keyword).then(res => {
        
    });
}

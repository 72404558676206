import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl'

import { Form } from 'react-bootstrap';

import Pagination from 'react-bootstrap/Pagination';

import './Pagination.scss';

import store from 'index';

const PaginationBar = observer(
    class PaginationBar extends Component {

        state = {};

        paginationStore = store.paginationStore;
        searchStore = store.searchStore;

        constructor(props) {
            super(props);
        };

        static getDerivedStateFromProps(props, state) {
            return null;
        }

        setPage = (page) => {
            this.paginationStore.setPage(page);
        }

        onSelect = (evt) => {
            this.props.setResults(parseInt(evt.target.value));
        }

        renderFirst = () => {
            if (!this.paginationStore.count) {
                return "";
            }
            return <Pagination.First aria-label="first" onClick={() => this.setPage(0)} />
        }

        renderPrev = () => {
            if (!this.paginationStore.count || !this.paginationStore.page) {
                return "";
            }
            return <Pagination.Prev aria-label="prev" onClick={() => this.setPage(this.paginationStore.page - 1)} />
        }

        renderEllipsisStart = () => {
            if (!this.paginationStore.count ||
                ((this.paginationStore.page + 1) - this.paginationStore.pages) < 0 ||
                this.paginationStore.pages < this.paginationStore.maxPages) {
                return "";
            }
            return <Pagination.Ellipsis disabled />
        }

        renderEllipsisEnd = () => {
            if (!this.paginationStore.count || (this.paginationStore.page - 1 + this.paginationStore.pages) >= this.paginationStore.last) {
                return "";
            }
            return <Pagination.Ellipsis disabled />
        }

        renderPages = () => {
            if (!this.paginationStore.count) {
                return "";
            }

            const pages = [];

            if (this.paginationStore.page === 0) {
                pages.push(<Pagination.Item title="Go to first page of results" key={Math.random()} active activeLabel={""}>{1}</Pagination.Item>);
                for (let i = 1; i < this.paginationStore.pages; i++) {
                    pages.push(<Pagination.Item title={`Go to page ${i + 1}`} key={Math.random()} onClick={() => this.setPage(i)}>{i + 1}</Pagination.Item>);
                }
                return pages;
            }

            if (this.paginationStore.page === this.paginationStore.last) {
                pages.push(<Pagination.Item title="Go to last page of results" key={Math.random()} active activeLabel={""}>{this.paginationStore.page + 1}</Pagination.Item>);
                for (let i = 1; i < this.paginationStore.pages; i++) {
                    pages.unshift(<Pagination.Item key={Math.random()} onClick={() => this.setPage(this.paginationStore.page - i)}>{this.paginationStore.page - (i - 1)}</Pagination.Item>);
                }
                return pages;
            }

            let page = this.paginationStore.page - Math.floor(this.paginationStore.pages / 2);
            for (let i = 0; i < this.paginationStore.pages; i++) {
                if (page + i === this.paginationStore.page) {
                    pages.push(<Pagination.Item key={Math.random()} title={`Go to page ${this.paginationStore.page + 1}`} active activeLabel={""}>{this.paginationStore.page + 1}</Pagination.Item>);
                } else {
                    pages.push(<Pagination.Item key={Math.random()} title={`Go to page ${page + i + 1}`} /*title={page}*/ onClick={() => this.setPage(page + i)}>{page + i + 1}</Pagination.Item>);
                }
            }

            return pages;
        }

        renderLastPage = () => {
            if (!this.paginationStore.count || (this.paginationStore.page + Math.floor(this.paginationStore.pages / 2)) >= this.paginationStore.last || this.paginationStore.pages < this.paginationStore.maxPages || this.paginationStore.pages === this.paginationStore.pageCount) {
                return "";
            }
            return <Pagination.Item title="Go to last page of results" onClick={() => this.setPage(this.paginationStore.last)}>{this.paginationStore.last + 1}</Pagination.Item>
        }

        renderNext = () => {
            if (!this.paginationStore.count || this.paginationStore.page >= this.paginationStore.last) {
                return "";
            }
            return <Pagination.Next aria-label="next" onClick={() => this.setPage(this.paginationStore.page + 1)} />
        }

        renderLast = () => {
            if (!this.paginationStore.count) {
                return "";
            }
            return <Pagination.Last aria-label="last" onClick={() => this.setPage(this.paginationStore.last)} />
        }

        renderResults = () => {
            return "";
            /*if(!this.props.showResults) return "";
                               
            return (
                <>
                <p>Results:</p>
                <Form.Control as="select" className={"Results"} value={this.props.results} onChange={this.onSelect}>
                    <option value={12}>12</option>
                    <option value={24}>24</option>
                    <option value={48}>48</option>
                </Form.Control>
                </>
            );
*/
        }

        render() {
            return (
                <div className={"Pagination"}>
                    <Pagination>
                        {this.renderFirst()}
                        {this.renderPrev()}
                        {/*<Pagination.Item onClick={() => this.setPage(0)}>{1}</Pagination.Item>*/}
                        {this.renderEllipsisStart()}

                        {this.renderPages()}

                        {this.renderEllipsisEnd()}
                        {this.renderLastPage()}
                        {this.renderNext()}
                        {this.renderLast()}
                    </Pagination>
                    {this.renderResults()}
                </div>
            );
        }
    }
)

export default withRouter(Flow.injectFlow(PaginationBar));

import { Nav, Row, Col } from 'react-bootstrap';
import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { Link, withRouter } from "react-router-dom";

import { FormattedMessage, injectIntl } from 'react-intl';

import { extendNav } from 'Util/DOM.js';

import { logout } from 'Util/Login.js';

import { _LANG_EN_, _LANG_CY_, _LINK_HELP_EN_, _LINK_HELP_CY_ } from "Config/constants";

import './Header.scss';

import store from 'index';

const Header = observer(
    class Header extends Component {

        searchStore = store.searchStore;

        // get account button if user is logged in
        // otherwise get login/register button
        renderButton = () => {
            if (process.env.REACT_APP_SERVER !== "EXT" && this.props.user) {
                return (
                    <>
                        <Nav.Item >
                            <Link
                                className="nav-link"
                                role="button"
                                to={{
                                    pathname: "/account",
                                    search: this.searchStore.createUrl()
                                }}>
                                <FormattedMessage id={"header.nav.account"} />
                            </Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Link
                                className="nav-link"
                                role="button"
                                to={{
                                    pathname: "/playlist",
                                    search: this.searchStore.createUrl()
                                }}>
                                <FormattedMessage id={"header.nav.playlist"} />
                            </Link>
                        </Nav.Item>
                        <Nav.Item >
                            <Link
                                className="nav-link"
                                role="button"
                                to={{}}
                                onClick={() => logout(this.props)}>
                                <FormattedMessage id={"header.nav.logout"} />
                            </Link>
                            {/*<a className="nav-link" role="button" onClick={() => logout(this.props)}>
                            <FormattedMessage id={"header.nav.logout"} />
                        </a>*/}
                        </Nav.Item>
                    </>
                );
            }
			
			if(process.env.REACT_APP_SERVER === "EXT") return "";

            return (
                <Nav.Item >
                    <Link
                        className="nav-link"
                        role="button"
                        to={{
                            pathname: "/login",
                            search: this.searchStore.createUrl()
                        }}>
                        <FormattedMessage id={"header.nav.login"} />
                    </Link>
                </Nav.Item>
            );
        }

        extendNav = (hrefs) => {
            const links = [];

            links.push({
                text: this.props.intl.formatMessage({ id: "header.nav.help" }),
                href: hrefs.help,
                callback: null
            });

            if (process.env.REACT_APP_SERVER !== "EXT" && this.props.user) {
                links.push({
                    text: this.props.intl.formatMessage({ id: "header.nav.logout" }),
                    href: "",
                    callback: this.props.onLogout
                });
                links.push({
                    text: this.props.intl.formatMessage({ id: "header.nav.playlist" }),
                    href: "/playlist",
                    callback: null
                });
                links.push({
                    text: this.props.intl.formatMessage({ id: "header.nav.account" }),
                    href: "/account",
                    callback: null
                });
            } else {
				if(process.env.REACT_APP_SERVER !== "EXT") {
					links.push({
						text: this.props.intl.formatMessage({ id: "header.nav.login" }),
						href: "/login",
						callback: null
					});					
				}
            }

            return links;
        }

        renderNavigation = () => {
            if (!this.props.flowconnected || this.props.sessionCheck) {
                return "";
            }

            const help = window._LANG_ === _LANG_EN_ ? _LINK_HELP_EN_ : _LINK_HELP_CY_;

            extendNav(this.extendNav({ help: help }));

            return (
                <Nav variant="pills" defaultActiveKey="/">
                    <Nav.Item >
                        <Link className="nav-link" role="button" to="/">
                            <FormattedMessage id={"header.nav.home"} />
                        </Link>
                    </Nav.Item>
                    {/*
					<Nav.Item >
						<Link className="nav-link" role="button" to="/search">
							<FormattedMessage id={"header.nav.advanced"} />
						</Link>
					</Nav.Item>
					*/}
                    {this.renderButton()}
                    <Nav.Item>
                        <Nav.Link href={help}>
                            <FormattedMessage id={"header.nav.help"} />
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            );
        }

        render() {
            return (
                <Row className={"ContentHeader"}>

                    <div id="Wave" style={{ backgroundImage: "url(/waveform.png)" }}></div>
                    <Col className={"Title"}>
                        <h1>
                            <Link className={"TitleLink"} role="button" to="/">
                                <FormattedMessage id={"header.title"} />
                            </Link>
                        </h1>
                        <p>
                            <FormattedMessage id={"header.text"} />
                        </p>
                    </Col>
                    <Col lg="auto" className={"nav d-none d-lg-flex"}>
                        {this.renderNavigation()}
                    </Col>
                </Row>
            );
        }
    }
)

export default Flow.injectFlow(injectIntl(withRouter(Header)));

import  { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import CheckboxFilter from './CheckboxFilter';

import './Filter.scss';

const Channel = observer(
    class Channel extends Component {
        
        data = [
            { value:'BBC 2W' },
            { value:'BBC Choice' },
            { value:'BBC One' },
            { value:'BBC One Wales' },
            { value:'BBC Two' }, 
            { value:'BBC Two Wales' }, 
            { value:'BBC Three' },
            { value:'BBC Four' }, 
            { value:'BBC Choice' }, 
            { value:'BBC Parliament' },
            { value:'BBC Radio 1' },
            { value:'BBC Radio 2' },
            { value:'BBC Radio 3' },
            { value:'BBC Radio 4' },
            { value:'BBC Radio 5 Live' },
            { value:'BBC Radio 5 Live Sports Extra' },
            { value:'BBC Radio 6 Music' },
            { value:'BBC Radio 7' },
            { value:'BBC Radio Cymru' },
            { value:'BBC Radio Wales' },
            { value:'Channel Four' },
            { value:'Channel Five' },
            { value:'Channel 5' }, 
            { value:'Five' },
            { value:'HTV Wales' },
            { value:'HTV West' },
            { value:'ITV Cymru Wales' },
            { value:'ITV West' },
            { value:'ITV1' }, 
            { value:'ITV1 Wales' },
            { value:'ITV2' },
            { value:'Radio Ceredigion' },
            { value:'S4C' }, 
            { value:'S4C2' }, 
            { value:'S4C Digidol' }
        ];
      
        state = {
            field: 'channelname'
        };
        
        constructor(props) {
            super(props);
        }
        
        createSearch = (filter) => {
            const search = [];
            
            if(filter.length) {
                search.push({field: "", type: "BEGIN_OR", value: ""});
                
                filter.forEach(value => {
                    search.push({field: this.state.field, type:"=", value: value});
                });
                
                search.push({field: "", type: "END_OR", value: ""});
            }
            
            return search;
        }

        render() {
            return (
                <CheckboxFilter 
                    {...this.props } 
                    data={this.data} 
                    field={this.state.field}
                    title={this.props.intl.formatMessage({id: "filter.channels"})}
                    filterable={true}
                    placeholder={this.props.intl.formatMessage({id: "filter.channels.input"})}
                    createSearch={this.createSearch}
                    facetCount={(this.props.facetCount && this.props.facetCount[this.state.field]) || false}/>
            );
        }
    }
)

export default injectIntl(Channel);

/*
const Channel = observer(
    class Channel extends Component {
        
        data = [
            { value:'BBC 2W' }, 
            { value:'BBC One' }, 
            { value:'BBC One Wales' },
            { value:'BBC Two' }, 
            { value:'BBC Two Wales' }, 
            { value:'BBC Three' },
            { value:'BBC Four' }, 
            { value:'BBC Choice' }, 
            { value:'BBC Parliament' },
            { value:'BBC Radio 1' },
            { value:'BBC Radio 2' },
            { value:'BBC Radio 3' },
            { value:'BBC Radio 4' },
            { value:'BBC Radio 5 Live' },
            { value:'BBC Radio 5 Live Sports Extra' },
            { value:'BBC Radio 6 Music' },
            { value:'BBC Radio 7' },
            { value:'BBC Radio Cymru' },
            { value:'BBC Radio Wales' },
            { value:'Channel 5' }, 
            { value:'Channel Four' },
            { value:'Five' }, 
            { value:'ITV1' }, 
            { value:'ITV1 Wales' },
            { value:'Radio Ceredigion' },
            { value:'S4C' }, 
            { value:'S4C2' }, 
            { value:'S4C Digidol' }
        ];
      
        state = {
            filter: [],
            field: 'channelname',
            open: false
        };
        
        constructor(props) {
            super(props);
            this.state.open = props.open || false;
            this.state.filter = (props.filter && props.filter[this.state.field] && props.filter[this.state.field].filter) || [];
        }
        
        static getDerivedStateFromProps(props, state) {
            // TODO: refactor - always a different object
            if(!Object.is(props.filter, state.filter)) {
                return {
                    filter: (props.filter && props.filter[state.field] && props.filter[state.field].filter) || []
                }
            }
            return null;
        }
        
        toggle = () => {
            this.setState({ open: !this.state.open });
        }
        
        onChange = (id, value) => {
            let filter = this.state.filter
            let i = filter.indexOf(value);
            
            if(i !== -1) {
                filter.splice(i, 1);
            } else {
                filter.push(value);
            }
            
            this.setState({ filter: filter });
            
            const search = this.createSearch(filter);
            this.props.setFilter({ [this.state.field]: { filter: filter, search: search } });
        }
        
        createSearch = (filter) => {
            const search = [];
            
            if(filter.length) {
                search.push({field: "", type: "BEGIN_OR", value: ""});
                
                filter.forEach(value => {
                    search.push({field: this.state.field, type:"=", value: value});
                });
                
                search.push({field: "", type: "END_OR", value: ""});
            }
            
            return search;
        }
        
        getChecked = () => {
            const data = this.data.filter(item => {
                return this.state.filter.includes(item.value);
            });
            return data;
        }
        
        getNotChecked = () => {
            const data = this.data.filter(item => {
                return !this.state.filter.includes(item.value);
            });
            return data;
        }
        
        getFiltered = (values) => {
            const data = values.filter(item => {
                if(!this.state.keyword) return true;
                return item.value.toLowerCase().indexOf(this.state.keyword.toLowerCase()) !== -1;
            });
            return data;
        }
        
        renderOutput = (data, checked = false) => {
            const output = data.map((item, index) => {
                let id = item.value.replace(" ", "").toLowerCase();
                return (
                    <Col className={"content-row"} key={id + checked} md={12}>
                        <Form.Check 
                            type={"checkbox"}
                            id={id}
                            defaultChecked={checked}
                            label={item.value}
                            onChange={() => {this.onChange(id, item.value);}}
                        />
                    </Col>
                );
            });
            return output;
        }

        render() {
            const checked = this.renderOutput(this.getChecked(), true);
            const notChecked = this.renderOutput(this.getFiltered(this.getNotChecked()));
            const output = checked.concat(notChecked);
            
            const open = this.state.open ? <span className={"caret-up"} /> : <span className={"caret-down"} />;
            
            const placeholder = this.props.intl.formatMessage({id: "filter.channels.input", defaultMessage: "Filter Channels" });
            
            return (
                <Col className={"Filter"}>
                    <Row className={"Header"} onClick={this.toggle}>
                        <Col>
                            <div className={"control"}>
                                <h5><FormattedMessage id={"filter.channels"} /></h5>
                                { open }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><Col className="Border"></Col></Col>
                    </Row>
                    <Row className={!this.state.open+""}>
                        <Col>{this.state.filter.join(', ')}</Col>
                    </Row>
                    <Row className={["filter-content", "searchable", this.state.open].join(' ')}>
                        <Col>
                            <Form.Control
                                value={this.state.keyword}
                                type="text" 
                                placeholder={placeholder} 
                                onChange={(evt) => this.setState({keyword:evt.target.value})}/>
                        </Col>
                        { output }
                    </Row>

                </Col>
            );
        }
    }
)

export default injectIntl(Channel);
*/

import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import Home from 'Pages/Home';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
      console.log("ERROR");
      // You can render any custom fallback UI
      /*return (
        <>
          <Route path="/" exact component={Home} />
          <Redirect to='/' />
        </>
      );*/
	  return this.props.children;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

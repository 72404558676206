import { Component } from 'react';
import { Flow } from 'flow-react';
import { observer } from "mobx-react"
import { withRouter } from 'react-router-dom';

import { FormattedMessage } from 'react-intl'

import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faTh, faList, } from '@fortawesome/free-solid-svg-icons'

import Channel from './Filter/Channel';
import Language from './Filter/Language';
import MediaType from './Filter/MediaType';
import Decade from './Filter/Decade';

import Publish from './Filter/Publish';

import Broadcast from './Filter/Broadcast';
import Production from './Filter/Production';
import Location from './Filter/Location';

import DisplayOptions from 'Components/DisplayOptions';

import Search from './Search';

import { VIEW_MODE_GRID, VIEW_MODE_LIST, SORTING_DIRECTION_ASC, SORTING_DIRECTION_DESC } from 'Config/constants';

import './HorizontalBar.scss';

import store from 'index';

const HorizontalBar = observer(
    class HorizontalBar extends Component {

        searchStore = store.searchStore;

        state = {
            filters: false
        }

        search = () => {
            //this.props.setSearch(true, this.props.fulltext);
            //this.props.history.push({ pathname: '/search' });
            this.searchStore.search();
        }

        clear = () => {
            this.props.clearFilter();
        }

        toggleFilters = () => {
            this.setState({ filters: !this.state.filters });
        }

        renderFilterControls = () => {
            return (
                <Row>
                    {/*
                <Col className={"Button"}>
                    <Button variant="outline-dark" onClick={this.search}>
                        <FormattedMessage id={"sidebar.button.apply"} />
                    </Button>
                </Col>
                */}
                    <Col className={"Button"}>
                        <Button variant="outline-dark" onClick={store.filterStore.clearFilter}>
                            <FormattedMessage id={"sidebar.button.clear"} />
                        </Button>
                    </Col>
                </Row>
            );
        }

        render() {
            //const filter = this.state.filters ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />;

            const filter = this.state.filters ? <span className={"caret-up"} /> : <span className={"caret-down"} />
            return (
                <Col className={"HorizontalBar d-lg-none"} >
                    <Row className={"PageHeader"}>
                        <Col>
                            <Search
                                {...this.props}
                                setValue={store.searchStore.setFulltext}
                                getValue={store.searchStore.getFulltext}
                                placeholder={"search.input.results"}
                                onSearch={store.searchStore.onFulltextSearch}
                                field={"fulltext"} />
                        </Col>
                    </Row>

                    <Row className={"Controls"} >
                        <Col className={"d-block"}>
                            <div className={"SidebarControl"} onClick={this.toggleFilters} tabIndex="0">
                                <p><FormattedMessage id={"sidebar.section.filter"} /></p>
                                {filter}
                            </div>
                        </Col>
                        <Col className={"HorizontalOptions d-flex"}>
                            <div></div>
                            {<DisplayOptions {...this.props} />}
                        </Col>
                        <Col className="ViewMode col-auto">
							<Button
								aria-label="grid"
								className={"Grid"}
								variant={store.uiStore.getViewMode()/*this.props.viewmode*/ === VIEW_MODE_GRID ? "light" : "outline-light"}
								onClick={() => store.uiStore.setViewMode(VIEW_MODE_GRID)/*this.props.setViewMode(VIEW_MODE_GRID)*/}
							>
								<FontAwesomeIcon icon={faTh} />
							</Button>
							<Button
								aria-label="list"
								className={"List"}
								variant={store.uiStore.getViewMode()/*this.props.viewmode*/ === VIEW_MODE_LIST ? "light" : "outline-light"}
								onClick={() => store.uiStore.setViewMode(VIEW_MODE_LIST)/*this.props.setViewMode(VIEW_MODE_LIST)*/}
							>
								<FontAwesomeIcon icon={faList} />
							</Button>
                        </Col>
                    </Row>



                    <div className={["Filters", "show-" + this.state.filters].join(' ')}>
                        {this.renderFilterControls()}
                        <hr />
                        <Publish open={true} setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <MediaType open={true} setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <Language open={true} setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <Channel setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <Decade setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} facetCount={this.props.facetCount} />
                        <Broadcast setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} />
                        <Production setFilter={this.props.setFilter} filter={this.props.filter} clear={this.props.clear} search={this.props.search} />
                        <hr />
                        {this.renderFilterControls()}
                    </div>
                </Col>
            );
        }
    }
)

export default withRouter(Flow.injectFlow(HorizontalBar));

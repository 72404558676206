import  { Row, Col, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl'
import { Component } from 'react';
import { observer } from "mobx-react";
import { injectIntl } from 'react-intl';

import CheckboxFilter from './CheckboxFilter';

import './Filter.scss';

const MediaType = observer(
    class MediaType extends Component {
        
        data = [
            { value:'video', label: this.props.intl.formatMessage({id: "filter.media.video"}) },
            { value:'audio', label: this.props.intl.formatMessage({id: "filter.media.audio"}) }/*,
            { value:'Pictures', label: this.props.intl.formatMessage({id: "filter.media.image"}) },
            { value:'Other', label: this.props.intl.formatMessage({id: "filter.media.document"}) }*/
        ];
      
        state = {
            field: 'media'
        };
        
        constructor(props) {
            super(props);
        }
        
        createSearch = (filter) => {
            const search = [];
            
            if(filter.length) {
				this.data.forEach(media => {
					if(!filter.includes(media.value)) {
						search.push({field: this.state.field, type:"NOT CONTAINS", value: media.value});
					}
				});
				
                search.push({field: "", type: "BEGIN_OR", value: ""});
                
                filter.forEach(value => {
                    search.push({field: this.state.field, type:"CONTAINS", value: value});
                });
                
                search.push({field: "", type: "END_OR", value: ""});
            }
            
            return search;
        }

        render() {
            return <CheckboxFilter 
                {...this.props }
                data={this.data}
                field={this.state.field}
                title={this.props.intl.formatMessage({id: "filter.media"})}
                createSearch={this.createSearch} />;
        }
    }
)

export default injectIntl(MediaType);

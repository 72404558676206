import { Row, Col, Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Component } from 'react';
import { observer } from "mobx-react";

import { format } from 'date-fns';

import './Filter.scss';

const Location = observer(
    class Location extends Component {

        state = {
            filter: "",
            field: 'location',
            open: false
        };

        constructor(props) {
            super(props);
            this.state.open = props.open || false;
            this.state.filter = (props.filter && props.filter[this.state.field] && props.filter[this.state.field].filter) || "";
        }

        static getDerivedStateFromProps(props, state) {
            // TODO: refactor - always a different object
            if (!Object.is(props.filter, state.filter)) {
                return {
                    /*filter: (props.filter && props.filter[state.field] && props.filter[state.field].filter) || ""*//*,
                    from: (props.filter && props.filter[state.field] && props.filter[state.field].filter && props.filter[state.field].filter.from) || { day: "", month: "", year: "" },
                    to: (props.filter && props.filter[state.field] && props.filter[state.field].filter && props.filter[state.field].filter.to) || { day: "", month: "", year: "" }*/
                }
            }
            return null;
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (prevState.filter && !this.state.filter) {
                this.onSearch();
            }
        }

        toggle = () => {
            this.setState({ open: !this.state.open });
        }

        onSearch = () => {
            const filter = this.state.filter;
            const search = this.createSearch();

            this.props.setFilter({ [this.state.field]: { filter: filter, search: search } });
        }



        createSearch = (filter) => {
            const search = [];
            if (this.state.filter) {
                search.push({ field: this.state.field, type: "CONTAINS", value: this.state.filter });
            }
            return search;
        }


        render() {
            const open = this.state.open ? <span className={"caret-up"} /> : <span className={"caret-down"} />;

            return (
                <Col className={"Filter"}>
                    <Row className={"Header"} onClick={this.toggle}>
                        <Col>
                            <div className={"control"}>
                                <h5><FormattedMessage id={"filter.location"} /></h5>
                                {open}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col><Col className="Border"></Col></Col>
                    </Row>
                    <Row className={!this.state.open + ""}>
                        <Col>{this.state.filter}</Col>
                    </Row>
                    <Row className={["filter-content", this.state.open].join(' ')}>
                        <Col>
                            <div className={"DateSearch"}>
                                <Form.Control
                                    aria-label="search-location"
                                    type="text"
                                    value={this.state.filter}
                                    placeholder="Search location"
                                    onChange={(evt) => this.setState({
                                        filter: evt.target.value
                                    })} />
                                <Button variant="primary" disabled={this.state.filter ? false : true} onClick={this.onSearch}>
                                    <FormattedMessage id={"common.search"} />
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Col>
            );
        }
    }
)

export default Location;

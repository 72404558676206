import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FlowProvider } from 'flow-react';
import {
    BrowserRouter as Router,
} from "react-router-dom";

import RootStore from 'store/RootStore';

import "Util/DOM";

import { _LANG_EN_, _LANG_CY_, _DOMAIN_CY_ } from "Config/constants";

//import './custom.scss';

import messages_de from "Lang/de.json";
import messages_en from "Lang/en.json";
import messages_cy from "Lang/cy.json";

export const messages = {
    'de': messages_de,
    [_LANG_EN_]: messages_en,
    [_LANG_CY_]: messages_cy
};

window._LANG_ = window.location.hostname.indexOf(_DOMAIN_CY_) === -1 ? _LANG_EN_ : _LANG_CY_;

export const Components = {};

const store = new RootStore();

const loadReact = () => {

    let apiurl = "https://nlw.demo.flowcenter.de/flow2/api/initws";
    
    //let apiurl = "http://localhost:3000/api/initws";

    //let apiurl = "https://clipdev.library.wales/flow2/api/initws";

    if (process.env.REACT_APP_SERVER === "PROD" || process.env.REACT_APP_SERVER === "EXT") {
        apiurl = "https://cliptest.library.wales/flow2/api/initws";
    } else if (process.env.REACT_APP_SERVER === "TEST") {
        apiurl = "https://clipdev.library.wales/flow2/api/initws";
    }

    /*process.env.REACT_APP_SERVER === "PROD" || process.env.REACT_APP_SERVER === "EXT" || process.env.REACT_APP_SERVER === "TEST" ? 
         window.location.protocol+"//"+window.location.hostname+"/flow2/api/initws" : 
         "https://nlw.demo.flowcenter.de/flow2/api/initws";
    */
    /*
        ReactDOM.render(
            <React.StrictMode>
                <FlowProvider apiurl={window.location.protocol+"//"+window.location.hostname+"/flow2/api/initws"} config={{pingtime: 20,throwonfail: true}}>
                    <Router>
                        <App />
                    </Router>
                </FlowProvider>
            </React.StrictMode>,
            document.getElementById('llgc_main_content')
        );
    */

    //console.log(apiurl);

    ReactDOM.render(
        <React.StrictMode>
            <FlowProvider apiurl={apiurl} config={{ pingtime: 20, throwonfail: true }}>
                {/*<Provider store={store}>*/}
                <Router>
                    {/* <Router basename="/nlw-frontend-test/"> */}
                    <App />
                </Router>
                {/*</Provider>*/}
            </FlowProvider>
        </React.StrictMode>,
        document.getElementById('llgc_main_content')
    );
}

document.addEventListener('DOMFinished', loadReact);

/*
ReactDOM.render(
  <React.StrictMode>
      <FlowProvider apiurl="https://10.60.4.1/flow2/api/initws" config={{pingtime: 20,throwonfail: true}}>
        <App />
      </FlowProvider>
  </React.StrictMode>,
  document.getElementById('root')
);*/

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default store;

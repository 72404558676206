
class MediaFile {
    static getMediaType(media, mediafiles) {
        if (!media || !media.length) {
            return MediaFile.getMediaTypeFromMediafiles(mediafiles);
        }

        if (media.indexOf("video") !== -1 || media.indexOf("sourcevideo") !== -1) {
            return 'video';
        }
        if ((media.indexOf("audio") !== -1)) {
            return 'audio';
        }
        if (media.indexOf("pictures") !== -1 && media.indexOf("video") === -1) {
            return 'pictures';
        }
        

        if ((media.indexOf("pictures") === -1 && media.indexOf("video") === -1 && media.indexOf("audio") === -1)) {
            return 'other';
        }
        
        return 'other';
    }
    
    static getMediaTypeFromMediafiles(mediafiles) {
        if(!mediafiles || !mediafiles.length) return 'other';
        
        mediafiles.sort((a, b) => {
            if(a.getId() < b.getId()) {
                return -1;
            }
            if(a.getId() > b.getId()) {
                return 1;
            }
            return 0;
        });
        
        const ext = MediaFile.getFileExtention(mediafiles[0].getFilename());
        
        if(ext) {
            if(MediaFile.hasVideoExt(ext)) return 'video';
            if(MediaFile.hasAudioExt(ext)) return 'audio';
            if(MediaFile.hasImageExt(ext)) return 'pictures';
        }
        
        return 'other';
    }
    
    static hasAudioExt(ext) {
        // https://caniuse.com/?search=audio%20format
        const validExt = ["wav", "mp3", "ogg", "flac"];
        
        return validExt.includes(ext);
    }
    
    static hasVideoExt(ext) {
        // https://en.wikipedia.org/wiki/HTML5_video
        const validExt = ["webm", "mp4", "ogg"];
        
        return validExt.includes(ext);
    }
    
    static hasImageExt(ext) {
        // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
        const validExt = ["apng", "avif", "gif", "jpg", "jpeg", "jfif", "pjpeg", "pjp", "png", "svg", "webp", "bmp", "ico", "cur", "tif", "tiff"];
        
        return validExt.includes(ext);
    }

    static shouldShow(media, mediafiles, filter) {
        if (filter.indexOf(MediaFile.getMediaType(media, mediafiles)) > -1) {
            return true;
        }
        return false;
    }

    static getFileExtention(fname) {
        return fname ? fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase() : null;
    }

    static getFileIcon(fname) {
        const icons = { doc: 'file-word', docx: 'file-word', xls: 'file-excel', xlsx: 'file-excel', wav: 'music', mp3: 'music', wma: 'music', ogg: 'music', pdf: 'file-pdf' }
        if (icons.hasOwnProperty(MediaFile.getFileExtention(fname))) {
            return icons[MediaFile.getFileExtention(fname)];
        }
        return 'file';
    }

    static formatBytes(bytes) {
        return (bytes / 1000000).toFixed(2) + ' MB';
    }

    static isAudio(fname) {
        const formats = ['mp3', 'wav', 'ogg', 'aac'];
        return formats.indexOf(MediaFile.getFileExtention(fname)) !== -1;
    }
    
    static isAAC(fname) {
        const formats = ['aac'];
        return formats.indexOf(MediaFile.getFileExtention(fname)) !== -1;
    }

    static isVideo(mediafile) {
        return mediafile && mediafile.videocodec;
    }

    static isImage(mediafile) {
        return mediafile && /*mediafile.height && mediafile.width &&*/ !mediafile.videocodec;
    }

    static hasFileExtention(fname) {
        return MediaFile.getFileExtention(fname) ? true : false;
    }

    static getAudioMIME(fname) {
        const mime = { mp3: 'audio/mpeg', wav: 'audio/wav', ogg: 'audio/ogg', aac: 'audio/aac' };
        return mime[MediaFile.getFileExtention(fname)];
    }

    static getCorrectAspectRatio(file, size) {
        if (file.height > file.width) {
            let ratio = file.width / file.height;
            let width = parseInt(size * ratio);
            return width + "x" + size;
        }
        if (file.width > file.height) {
            let ratio = file.height / file.width;
            let height = parseInt(size * ratio);
            return size + "x" + height;
        }
        return size + "x" + size;
    }

    static getInformation(file) {
        let info = [];
        let ext = file.getFilename().split('.').pop().toLowerCase();

        info.push((file.width && file.height) ? (file.width + "x" + file.height) : "-");
        info.push(file.filesize ? MediaFile.formatBytes(file.filesize) : "-");
        if (file.aspect_ratio) {
            info.push(file.aspect_ratio || "-");
        }
        info.push(ext || "-");

        return info.join(' | ');
    }
	
	static getMainMediafile(clip, derivatives = [{ field: 'tags', value: 'preview' }]) {
        let mediafiles = clip.mediafiles.elements ? clip.mediafiles.elements : [];
        let mainmediafile = null;
        let i = 0;

        if (mediafiles.length) {
            while (!mainmediafile && i < derivatives.length) {
                mainmediafile = MediaFile.findDerivate(mediafiles, derivatives[i]);
                i++;
            }
            // no match found >> set first mediafile
            if (!mainmediafile) {
                mainmediafile = mediafiles[0];
            }
        }

        return mainmediafile;
    }

    static findDerivate(mediafiles, derivate) {
        for (let i = 0; i < mediafiles.length; i++) {
            let data = mediafiles[i].data;
            let field = data[derivate.field];

            // make string and split to array to find value
            if (!Array.isArray(field)) {
                field = (data[derivate.field] + "").split(',');
            }

            if (field.indexOf(derivate.value) !== -1) {
                return mediafiles[i];
            }
        }

        return null;
    }
}

export default MediaFile;

import FilterStore from './FilterStore';
import SearchStore from './SearchStore';
import PaginationStore from './PaginationStore';
import UiStore from './UiStore';
import AccountStore from './AccountStore';
import GroupSearchStore from './GroupSearchStore';

export default class RootStore {
    initStore = true;
	
	constructor() {
        this.filterStore = new FilterStore(this);
        this.searchStore = new SearchStore(this);
        this.paginationStore = new PaginationStore(this);
        this.uiStore = new UiStore(this);
        this.accountStore = new AccountStore(this);
        this.groupSearchStore = new GroupSearchStore(this);
    }
	
	init = () => {
		this.initStore = true;
        this.uiStore.init();
		this.filterStore.init();
        this.searchStore.init();
        this.paginationStore.init();
        this.accountStore.init();
        this.groupSearchStore.init();
		this.initialized();
	}
	
	initialized = () => {
		this.initStore = false;
		this.searchStore.search();
	}
}
